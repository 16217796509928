<template>
  <div
    class="customSwiper-wrap px-0"
    :class="{
      'hide-disabled-nav': hideDisabledNav,
    }"
  >
    <slot></slot>
    <swiper
      class="customSwiper swiper"
      :class="{
        'pag-with-nav-at-bottom': pagAndNavAtBottom,
        'fit-content': fitContent,
      }"
      :options="swiperOption"
      @slideChange="slideChange"
      @slideNextTransitionEnd="slideNextTransitionStart"
      @slidePrevTransitionStart="slidePrevTransitionStart"
      @click="swiperClick"
      @clickSlide="swiperClickSlide"
    >
      <swiper-slide v-for="(item, index) in items" :key="index">
        <slot name="component" :item="item" :index="index"></slot>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
      <!-- <div class="swiperGradient"></div> -->
    </swiper>
    <template v-if="hasNav">
      <div :class="{'banner-swiper-button-wrapper': isPageBanner}">
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </div>
    </template>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  props: {
    swiperSetting: {
      type: Object,
      default: () => null,
    },
    items: {
      type: Array,
    },
    pagAndNavAtBottom: {
      type: Boolean,
      default: false,
    },
    fitContent: {
      type: Boolean,
      default: false,
    },
    hideDisabledNav: {
      type: Boolean,
      default: false,
    },
    navAlwaysGreen: {
      type: Boolean,
      default: false,
    },
    isPageBanner: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiper: null,
      prevBtn: null,
    };
  },
  computed: {
    isDark() {
      return this.$store.getters["base/isDark"];
    },
    nextBtnImg() {
      if (this.navAlwaysGreen)
        return require("@/assets/img/new-next-btn-g.svg");
      if (this.isDark) return require("@/assets/img/new-next-btn-w.svg");
      return require("@/assets/img/new-next-btn-g.svg");
    },
    preBtnImg() {
      if (this.navAlwaysGreen)
        return require("@/assets/img/new-previous-btn-g.svg");
      if (this.isDark) return require("@/assets/img/new-previous-btn-w.svg");
      return require("@/assets/img/new-previous-btn-g.svg");
    },
    defaultSwiperOption() {
      return {
        slidesPerView: "auto",
        spaceBetween: 5,
        loop: false,
        // slideToClickedSlide: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      };
    },
    swiperOption() {
      return {
        ...this.defaultSwiperOption,
        ...this.swiperSetting,
        ...(this.fitContent ? { slidesPerView: "auto" } : null),
      };
    },
    hasNav() {
      return (
        Object.keys(this.swiperOption.navigation).length > 0 &&
        Object.keys(this.swiperSetting.navigation || {}).length === 0
      );
    },
  },
  async mounted() {
    this.getSwiper();
    if (this.pagAndNavAtBottom) await this.set_pagAndNav_to_Bottom();
    else await this.set_nav_to_side();
    if (this.fitContent) {
      await this.$helper.delay(0.5);
      this.swiper.slideTo(0, 0);
    }
    await this.$nextTick();
    this.update();
  },
  methods: {
    async set_pagAndNav_to_Bottom() {
      this.prevBtn = $(this.$el.querySelector(".swiper-button-prev"));
      this.nextBtn = $(this.$el.querySelector(".swiper-button-next"));
      await this.$nextTick();
      this.prevBtn.appendTo($(this.$el.querySelector(".swiper-pagination")));
      this.nextBtn.appendTo($(this.$el.querySelector(".swiper-pagination")));
    },
    async set_nav_to_side() {
      if (!this.hasNav) return;
      const parentElm = this.isPageBanner ? $(this.$el.querySelector(".banner-swiper-button-wrapper")) : $(this.$el)
      this.prevBtn = $(this.$el.querySelector(".swiper-button-prev"));
      this.nextBtn = $(this.$el.querySelector(".swiper-button-next"));
      await this.$nextTick();
      this.prevBtn.appendTo(parentElm);
      this.nextBtn.appendTo(parentElm);
    },
    getSwiper() {
      this.swiper = this.$el.querySelector(".swiper-container").swiper;
    },
    update() {
      this.swiper.update()
      this.$emit('swiperInstance', this.swiper)
    },
    slideChange() {
      this.$emit("slideChange");
    },
    slideNextTransitionStart() {
      this.$emit("slideNextTransitionStart");
    },
    slidePrevTransitionStart() {
      this.$emit("slidePrevTransitionStart");
    },
    swiperClick(e){
      this.$emit("swiperClick", e);
    },
    swiperClickSlide(clickedI, realI){
      this.$emit("swiperClickSlide", {clickedI, realI});
    },
  },
};
</script>

<style lang="sass" type="text/sass" scoped>
.banner-swiper-button-wrapper
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  display: flex
  justify-content: space-between
  align-items: center
  padding: 0 60px
  max-width: 1600px
  width: 100%
  height: 60px
  z-index: 99
  pointer-events: none
  .swiper-button-prev, .swiper-button-next
    position: static
    pointer-events: auto
.banner-swiper
  .swiper-slide
    &.swiper-slide-prev, &.swiper-slide-next
      opacity: 0.3 !important
    &.swiper-slide-active
      transition: opacity 0.8s
      opacity: 1 !important
</style>
