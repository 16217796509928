import Vue from 'vue'
export default {
  data: () => ({
    hasPageGap: true,
  }),
  beforeDestroy() {
    this.hasPageGap = true
    this.$store.dispatch('base/setBreadcrumb', [])
    this.$store.dispatch('base/setBreadcrumbScope', 'default')
  },
  methods: {
    setPageGap(status) {
      this.hasPageGap = status
    },
    setupLayout() {
      Vue.prototype.$setPageGap = this.setPageGap
      this.$store.dispatch('base/setLayout', this.layoutName)
    },
  },
  computed: {
    application() {
      return this.$store.getters['base/application']
    },
    site() {
      return this.$store.getters['base/site']
    },
    logoUrl() {
      return this.$store.getters['base/logoUrl']
    },
    isUser() {
      return this.$store.getters['token/isUser']
    },
    isMaintainer() {
      return this.$store.getters['token/isMaintainer']
    },
    isMember() {
      return this.$store.getters['token/isMember']
    },
    isMobile() {
      return this.$store.getters['base/isMobile']
    },
    applicationLoaded() {
      return this.$store.getters['base/applicationLoaded']
    },
  },
  components: {
    adminControl: () => import('components/adminControl/adminControl.vue'),
    breadcrumb: () => import('components/breadcrumb/breadcrumb.vue'),
  },
}