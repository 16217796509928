import tokenStore from 'libs/tokenStore.js'
export default {
  namespaced: true,
  state: {
    tokenStore,
    isLogin: tokenStore.isValid(),
  },
  mutations: {
    set(state, token) {
      state.tokenStore.set(token)
    },
    clean(state) {
      state.tokenStore.clean(true)
    },
    updateIsLogin(state) {
      state.isLogin = state.tokenStore.isValid()
    },
    setScope(state, scope) {
      state.tokenStore.setScope(scope)
    }
  },
  actions: {
    set(context, token) {
      context.commit('set', token)
      context.commit('updateIsLogin')
    },
    clean(context) {
      context.commit('clean')
      context.commit('updateIsLogin')
    },
    setScope(context, scope) {
      context.commit('setScope', scope)
    },
  },
  getters: {
    tokenStore: state => state.tokenStore,
    enduringTokenScope: state => {
      return `${state.tokenStore.scope}Enduring`
    },
    scope: state => state.tokenStore.scope,
    token: state => state.tokenStore.token,
    hasRole: state => {
      return (roles) => state.tokenStore.hasRole(roles)
    },
    tokenId: state => {
      if(!state.tokenStore) return null
      return state.tokenStore.getId()
    },
    expiredAt: state => state.tokenStore.expiredAt,
    type: state => state.tokenStore.type,
    data: state => state.tokenStore.data,
    roles: (state, getters) => !getters.data ? null : getters.data.roles,
    avatar: state => {
      if(!state.tokenStore) return null
      if(!state.tokenStore.data) return null
      if(!state.tokenStore.data.avatar) return null
      return state.tokenStore.data.avatar
    },
    isLogin: state => state.isLogin,
    gaUserType: (state, getters) => {
      if(getters.isMember === true) return 'member'
      return 'anonymous'
    },
    isMember: state => {
      if(!state.isLogin) return false
      if(state.tokenStore.type != 'member') return false
      return true
    },
    isUser: state => {
      if(!state.isLogin) return false
      if(state.tokenStore.type != 'user') return false
      return true
    },
    isAdminUser: state => {
      if(!state.isLogin) return false
      if(state.tokenStore.type != 'user') return false
      return state.tokenStore.hasRole(['ROLE_ADMIN'])
    },
    isMaintainer: state => {
      if(!state.isLogin) return false
      if(state.tokenStore.type != 'user') return false
      return state.tokenStore.hasRole(['ROLE_MAINTAINER'])
    },
  },
}
