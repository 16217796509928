import storage from 'libs/storage/localStorage.js'

/**
 * 測試的WebviewCallbackObject
 * 模擬local建立一個JavascriptInterface
 * localStorage建立個key為"testAppCallback", value為1的資料reload後即可使用(main.js可調整設定)
 */
class mockJavascriptInterface {
  // callHandler(methodName, ...args) {
  //   console.warn('Webview callback methodName', methodName)
  //   console.warn('args', args)
  // }
  getFingerprint(fingerprint) {
    console.warn('[Webview Callback] getFingerprint', fingerprint)
  }

  getToken(token) {
    console.warn('[Webview Callback] getToken', token)
  }

  getEnduringToken(token) {
    console.warn('[Webview Callback] getEnduringToken', token)
  }

  loginSuccessfully() {
    console.warn('[Webview Callback] loginSuccessfully')
  }

  returnAppToken() {
    console.warn('[Webview Callback] setLogin')
    const tokenKey = `webview-${window.scopeRules.roles.member}`
    const enduringTokenKey = `webview-${window.scopeRules.roles.member}Enduring`

    return {
      token: storage.get(tokenKey),
      enduringToken: storage.get(enduringTokenKey),
    }
  }
}

export default new mockJavascriptInterface()