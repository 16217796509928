export default {
  props: {
    disabled: Boolean,
    video: {
      type: Object,
      default: () => null,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    startTime: {
      type: [String, Number],
      default: 0,
    },
    width: Number,
    height: Number,
  },
  data: () => ({
    time: 0,
    updateWatchTimeCurrentTimeInterval: null,
    getCurrentTimeCallback: null,
    watchDuration: 0,
    watchDurationTotal: 0,
  }),
  beforeDestroy() {
    this.removeDetectWatchInterval()
    this.watchDurationTotal = 0
  },
  methods: {
    removeDetectWatchInterval() {
      window.clearInterval(this.updateWatchTimeCurrentTimeInterval)
      this.updateWatchTimeCurrentTimeInterval = null
    },
    async startDetectWatchInterval() {
      console.warn('startDetectWatchInterval....')
      this.updateWatchTimeCurrentTimeInterval = null
      window.clearInterval(this.updateWatchTimeCurrentTimeInterval)

      this.time = this.getCurrentTime()
      this.watchDuration = this.videoWatchDetectIntervalTime // 觀看時間加N秒
      this.$emit('watchDetect', {
        time: this.time,
        watchDuration: this.watchDuration,
        immediate: true,
      })

      await this.$nextTick()
      this.updateWatchTimeCurrentTimeInterval = window.setInterval(() => {
        this.time = this.getCurrentTime()
        if(this.time === undefined) return
        this.watchDuration = this.videoWatchDetectIntervalTime // 觀看時間加N秒
        this.$emit('watchDetect', {
          time: this.time,
          watchDuration: this.watchDuration,
        })

        this.addBonus()
      }, this.videoWatchDetectIntervalTime*1000)
    },
    async addBonus() {
      if(!this.isMember) return // 不是會員不用發api
      if(!this.videoId) return // 無video不用發
      this.watchDurationTotal += this.videoWatchDetectIntervalTime
      if(this.watchDurationTotal < 180) return // 每180秒發一次並清空counter
      this.watchDurationTotal = 0

      try {
        await this.$api.video.public.addBonus({
          video_id: this.videoId,
        })
      } catch (error) {
        console.error(error)
      }
    },
    getCurrentTime() {
      if(typeof this.getCurrentTimeCallback != 'function') return undefined
      return this.getCurrentTimeCallback()
    },
    onVideoFirstPlay(triggerDetectWatchInterval = true) {
      if(triggerDetectWatchInterval) this.startDetectWatchInterval()
      this.$emit('firstPlay')
    },
    onVideoPlay() {
      this.startDetectWatchInterval()
      this.$emit('play')
    },
    onVideoPause() {
      this.removeDetectWatchInterval()
      this.$emit('pause')
    },
    onVideoEnd() {
      this.removeDetectWatchInterval()
      this.$emit('end')
    },
  },
  computed: {
    isMember() {
      return this.$store.getters['token/isMember']
    },
    videoId() {
      if(!this.video) return null
      return this.video.id
    },
    videoWatchDetectIntervalTime() {
      return this.$store.getters['base/videoWatchDetectIntervalTime']
    },
    photoUrl() {
      if(!this.video) return null
      return this.$helper.getPhotoUrl(this.video.photo, ['middle', 'small'])
    },
    containerStyle() {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`,
      }
    },
  },
}
