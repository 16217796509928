import cloneDeep from 'lodash/cloneDeep'
import uniq from 'lodash/uniq'
import random from 'lodash/random'
import crosstab from 'crosstab'
import visibilityjs from 'visibilityjs'
import { scopeRules } from 'config/token.json'
import storage from 'libs/storage/localStorage.js'
import apiLibrary from 'libs/api/api.js'
import moment from 'moment'
class tokenStore {
  constructor() {
    this.timeout = null
    this.scope = null
    this.setupScope()
    this.refreshData()
    this.renewLoading = false
    this.isTabFocus = false
    this.renewFailureCounts = 0
    this.detectTabFocus()

    // token過期或格式錯誤就清除
    if(!this.isValid()) {
      this.clean()
      return
    }
  }

  detectTabFocus() {
    if(visibilityjs.isSupported === false) {
      this.isTabFocus = true
      return
    }

    if(visibilityjs.state() === 'visible') {
      this.isTabFocus = true
    }

    visibilityjs.change((event, state) => {
      if(state == 'visible') {
        this.isTabFocus = true
      }

      else if(state == 'hidden'){
        this.isTabFocus = false
      }
    })
  }

  _getFallback() {
    for(const tokenScopeName of scopeRules.fallback) {
      const exist = !!storage.get(tokenScopeName)
      if(exist) return tokenScopeName
    }
    return scopeRules.fallback[scopeRules.fallback.length-1]
  }

  setupScope() {
    const path = window.location.pathname
    this.scope = this._getFallback()
    window.scopeRules = scopeRules
    const urlQuery = new URLSearchParams(window.location.search)
    const queryRole = urlQuery.get('role')

    if(queryRole && scopeRules.roles[queryRole]) {
      this.scope = scopeRules.roles[queryRole]
      return
    }

    for(const refPath in scopeRules.items) {
      const scopeName = scopeRules.items[refPath]
      const match = new RegExp(refPath, 'g').test(path)
      if(!match) continue
      this.scope = scopeName
    }
  }

  setScope(scope) {
    this.scope = scope
  }

  refreshData() {
    this.token = this.get()
    this.data = this.getData()
    this.type = this.getType()
    this.info = this.getInfo()
    this.expiredAt = this.getExpiredAt()
  }

  get() {
    return storage.get(this.scope)
  }

  set(token) {
    if(this.get() != token) {
      try {
        crosstab.broadcast('setToken', {
          token,
          scope: this.scope,
        } , null)
      } catch(error) {
        console.log('device not support crosstab[set token]')
      }
    }
    storage.set(this.scope, token)
    this.renewFailureCounts = 0
    this.refreshData()
    this.countdown()
  }

  clean(cleanEnduringToken = false) {
    if(this.get() != null) {
      try {
        crosstab.broadcast('cleanToken', {
          scope: this.scope,
        } , null)
      } catch(error) {
        console.log('device not support crosstab[token clean]')
      }
    }
    storage.delete(this.scope)
    if(cleanEnduringToken) storage.delete(`${this.scope}Enduring`)
    this.renewFailureCounts = 0
    this.refreshData()
  }

  async renew() {
    if(!window.kernel) {
      setTimeout(() => {
        this.renew()
      }, 1000)
      return
    }

    // 背景不更新以節省資源
    // if(!this.isTabFocus) return

    this.api = await apiLibrary()
    window.clearTimeout(this.timeout)
    if(!this.api.request) {
      setTimeout(() => this.renew(), 100)
      return
    }

    if(this.renewLoading) return
    this.renewLoading = true

    try {
      const result = await this.renewTokenApi()
      if(!result) return
      this.set(result.token)
    } catch (error) {
      console.error('renew token fail', error)
      this._handleRenewFailure(error)
    } finally {
      this.renewLoading = false
    }
  }

  _handleRenewFailure(error) {
    this.renewFailureCounts = this.renewFailureCounts+1
    if(this.renewFailureCounts < 5) {
      setTimeout(() => this.countdown(), 5000)
      return
    }
    this.renewFailureCounts = 0
    this.clean()
  }

  async renewTokenApi() {
    const type = this.getType()
    if(type == 'user') return this.api.userAccount.admin.renewToken()
    if(type == 'member') return this.api.member.public.renewToken()
  }

  now() {
    return Math.floor(new Date().getTime()/1000)
  }

  getId() {
    const info = this.getInfo()
    if(!info) return null
    return info.id
  }

  getType() {
    const info = this.getInfo()
    if(!info) return null
    return info.type
  }

  getRoles() {
    const info = this.getInfo()
    if(!info) return []
    if(!info.data) return []
    return this.handleRoles(info.data.roles)
  }

  getData() {
    const info = this.getInfo()
    if(!info) return null
    if(info.data.roles) {
      info.data.roles = this.handleRoles(info.data.roles)
    }
    return info.data
  }

  handleRoles(originalRoles) {
    let roles = cloneDeep(originalRoles)
    if(!roles) return roles
    let arrayRole = []
    if(typeof roles === 'object') {
      for(const key in roles) {
        arrayRole.push(roles[key])
      }
    }
    roles = arrayRole
    return uniq(roles)
  }

  // 取得剩餘時間(單位: 秒)
  getTokenSurplusTime() {
    if(!this.get()) return -1
    const info = this.getInfo()
    const now = this.now()
    if(!info) return -1
    const surplusTime = info.exp - now
    return surplusTime
  }

  // 倒數計時
  countdown() {
    if(!this.get()) return
    const surplusTime = this.getTokenSurplusTime()
    const renewAt = random(6*60, 10*60)

    // 剩餘時間>5分鐘
    if(surplusTime > renewAt) {
      this.timeout = window.setTimeout(() => {
        this.renew()
      }, (surplusTime - renewAt) * 1000)
      return
    }

    // 剩餘時間在5分鐘內
    if (surplusTime <= renewAt && surplusTime > 0) {
      window.setTimeout(() => {
        this.renew()
      }, random(100, 800))
      return
    }

    // token過期直接清除
    this.clean(true)
  }

  hasRole(requiredRoles) {
    const tokenRoles = this.getRoles()

    // 沒設定
    if(!Array.isArray(requiredRoles)) return true

    // 權限是工程帳號
    if(tokenRoles.indexOf('ROLE_MAINTAINER') > -1) return true

    for(const role of tokenRoles) {
      // token內的權限有一個是符合權限的設定
      if(requiredRoles.indexOf(role) > -1) return true
    }

    return false
  }

  // 是否有效
  isValid() {
    if(!this.get()) return false
    if(!this.getInfo()) return false
    const now = this.now()
    return this.getInfo().exp && this.getInfo().exp > now
  }

  // 取得token中的資料
  getInfo() {
    if(!this.get()) return null
    try {
      const payload = (this.get().split('.')[1]).replace(/-/g, '+').replace(/_/g, '/')
      const tokenInfo = JSON.parse(atob(payload))
      return tokenInfo
    } catch(error) {
      console.warn(error)
      return null
    }
  }

  getExpiredAt() {
    if(!this.data) return null
    if(!this.data.expired_at) return null
    return moment(this.data.expired_at).format('YYYY-MM-DD HH:mm:ss')
  }

  isMaintainer() {
    if(!this.isValid()) return false
    const roles = this.getRoles()
    return roles.includes('ROLE_MAINTAINER')
  }
}

export default new tokenStore()