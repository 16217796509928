import Vue from 'vue'

import Vuetify from 'vuetify/lib'
import { Ripple } from 'vuetify/lib/directives'

Vue.use(Vuetify, {
  directives: {
    Ripple,
  },
})

export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      cms: {
        primary: '#1190cb',
        success: '#4caf50',
        secondary: '#484848',
        accent: '#70bce0',
        error: '#e57373',
        warning: '#fb8c00'
      },
      light: {
        primary: '#56AF31',
        success: '#4caf50',
        secondary: '#484848',
        accent: '#70bce0',
        error: '#e57373',
        googleRed: '#FEFFFE',
        fbBlue: '#1877F2',
        lineGreen: '#2DAD38',
        black: '#1F1F1F'

        // ...{
        //   primary: '#e2175b',
        //   secondary: '#484848',
        //   accent: '#ff80aa',
        //   error: '#e57373',
        // }
      },
    },
  },
})
