import seriesRule from './customizeRouteRule/series.json'
const computedSeriesRule = seriesRule
  .filter(row => !!row.toPath)
  .map(row => ({
    path: row.fromPath,
    redirect: row.toPath
  }))

export default [
  // 營運理念
  { path: '/principle', redirect: '/service/about/article/article1' },

  // 經營團隊
  { path: '/team-member', redirect: '/service/about/article/group' },

  // 客台台歌
  { path: '/song', redirect: '/service/about/article/article3' },

  // 交通位置
  { path: '/traffic', redirect: '/service/about/article/article8' },

  // 監督機制
  { path: '/oversee-mechanism', redirect: '/service/management/oversee' },

  // 大事紀要
  { path: '/big-events', redirect: '/service/management/big-events' },

  // 線上客服
  { path: '/customer-service', redirect: '/service/audience/custom-service' },

  // 客台參訪
  { path: '/visit', redirect: '/service/audience/visit' },

  // 客台徵案
  { path: '/wanted', redirect: '/service/others/project' },

  // 公眾近用
  { path: '/upload_file/project/3/Application.pdf', redirect: '/service/others/article/article11' },

  // 客台徵才
  { path: '/wanted', redirect: '/service/others/hire' },

  // 兒少網
  { path: '/program-category/2', redirect: '/child' },

  // 戲劇網
  { path: '/program-category/1', redirect: '/drama' },

  ...computedSeriesRule,
]