<template>
  <hakkatvLayout layout-name="public"></hakkatvLayout>
</template>

<script lang="babel" type="text/babel">
import hakkatvLayout from 'layouts/hakkatvLayout'
export default {
  components: {
    hakkatvLayout,
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>