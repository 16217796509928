export default {
  methods: {
    async setupTracker() {
      if(this.isLightHouse) return

      // if(this.gtmId) {
      //   this.appendScript(this.gtmScript)
      // }

      // if(this.gaId) {
      //   await import(
      //     /* webpackChunkName: "autotrack" */
      //     /* webpackPrefetch: true */
      //     'autotrack'
      //   )
      //   this.appendScript(this.gaScript)
      // }

      if(this.ga4Id && this.isInFrontend) {
        window.ga4Id = this.ga4Id
        this.appendScript(this.ga4Script)
      }

      this.appendScript(this.pixelScript)
    },
    appendScript(content) {
      $('head').append(content);
    }
  },
  computed: {
    isLightHouse() {
      if(navigator.userAgent.indexOf('Page Speed') > -1) return true
      if(navigator.userAgent.indexOf('Speed Insights') > -1) return true
      if(navigator.userAgent.indexOf('Chrome-Lighthouse') > -1) return true
      return false
    },
    gtmId() {
      if(!this.trackerConfig) return null
      return this.trackerConfig.gtm.id
    },
    gtmScript() {
      if(!this.trackerConfig) return null
      return `<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${this.trackerConfig.gtm.id}');</script>`
    },
    gaId() {
      if(!this.trackerConfig) return null
      return this.trackerConfig.ga.id
    },
    ga4Id() {
      return 'G-ZQ4EFXNX11'
      if(!this.trackerConfig) return null
      return this.trackerConfig.ga4.id
    },
    gaScript() {
      if(!this.trackerConfig) return null
      return `<script>(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
        ga('create', '${this.trackerConfig.ga.id}', 'auto');

        ga('require', 'ec');
        ga('require', 'eventTracker');
        ga('require', 'urlChangeTracker');

        </script>`
    },
    ga4Script() {
      if(!this.trackerConfig) return null
      return `
        <script async src="https://www.googletagmanager.com/gtag/js?id=${this.ga4Id}"></script>
        <script>
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());


            gtag('config', '${this.ga4Id}', {'send_page_view': false});
        </script>
        `
    },
    pixelScript() {
      return `<script>
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '386331216188628');
        fbq('track', 'PageView');
      </script>
      <noscript><img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=386331216188628&ev=PageView&noscript=1"/></noscript>`
    },
    application() {
      return this.$store.getters['base/application']
    },
    trackerConfig() {
      if(!this.application) return null
      return this.application.tracker
    },
    siteName() {
      return this.$store.getters['base/siteName']
    },
    seo() {
      return this.$store.getters['base/seo']
    },
    language() {
      return this.$store.getters['locale/language']
    },
    layout() {
      return this.$store.getters['base/layout']
    },
    isInFrontend() {
      return ['public', 'member'].includes(this.layout)
    },
  },
}