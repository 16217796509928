<template>
  <v-list dense v-if="tokenData">
    <v-list-item>
      <v-list-item-avatar>
        <v-img :src="$avatar()"></v-img>
      </v-list-item-avatar>

      <v-list-item-title>
        {{tokenData.name}}

        <v-chip small class="ma-2" color="green" text-color="white" v-if="isMaintainer">
          <v-icon small>fa fa-user-circle</v-icon>
          <span class="pa-2">{{'user.data.maintainer'| i18n}}</span>
        </v-chip>
      </v-list-item-title>
    </v-list-item>

    <!--管理員資訊-->
    <v-list-item>
      <v-list-item-content>
        <v-list-item-subtitle class="mb-2">
          {{tokenData.email}}
        </v-list-item-subtitle>

        <div class="my-2">
          <v-btn small class="mr-4" color="primary" :to="{ name: 'admin.account.profile' }">
            {{'data.profile'| i18n}}
          </v-btn>
          
          <v-btn small class="mr-4" color="primary" @click="logout">
            {{'action.logout'| i18n}}
          </v-btn>
        </div>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-divider></v-divider>

    <!--module menu-->
    <component
      v-for="(node, index) in menu"
      :is="getComponent(node)"
      :key="index"
      :node="node">
    </component>

  </v-list>
</template>

<script lang="babel" type="text/babel">
import navigationMenuMixin from 'layouts/_menuNode/navigationMenuMixin.js'
export default {
  mixins: [navigationMenuMixin],
  data() {
    return {
      menu: null,
    }
  },
  mounted() {
    this.renderMenu()
  },
  methods: {
    async renderMenu() {
      let menu = this.getComputedNodeList(this.adminMenu)
      this.updateMenuByModule(menu)
      this.menu = this.baseLinks.concat(menu)
      await this.$nextTick()
      this.$root.$emit('onPageResize')
    },
    logout() {
      this.$helper.logout(this)
    },
  },
  computed: {
    baseLinks() {
      return [
        {
          icon: 'fa fa-home',
          label: 'page.home',
          type: 'route',
          route: { name: 'home' },
          shouldCreate: true,
        },
        {
          icon: 'fa fa-tachometer-alt',
          label: 'page.console',
          type: 'route',
          route: { name: 'admin.home' },
          shouldCreate: true,
        },
      ]
    },
    adminMenu() {
      return this.$store.getters['base/adminMenu']
    },
    isMaintainer() {
      return this.$store.getters['token/isMaintainer']
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>