import i18nLibrary from 'libs/i18n.js'
import storage from 'libs/storage/localStorage.js'
export default {
  data() {
    return {
      i18nLibrary: null,
    }
  },
  methods: {
    async loadText(init = false) {
      if(init) {
        window.i18nLibrary = i18nLibrary
        if(!this.application) return
        window.i18nLibrary.init(this.application.i18n.fallback, this.application.i18n.languages)
        const availableLanguage = window.i18nLibrary.getAvailableLanguage(this.storageLanguage)
        this.$store.dispatch('locale/setLanguage', availableLanguage)
        this.initStorageLanguage(availableLanguage)
        if(this.$vDatetimePicker) this.$vDatetimePicker.locale = this.clientLanguage
      }

      try {
        // const result = await this.$api.text.public.all(this.textVersion, this.textCache)
        const result = await this.$api.text.public.textFromJson(this.textVersion, this.textCache)
        this.$store.dispatch('base/set', {
          key: 'textHasLoaded',
          value: true,
        })
        window.i18nLibrary.launch({
          text: result.data,
        }, () => this.clientLanguage)
        this.$store.dispatch('locale/setLanguages', this.application.i18n.languages)
      } catch (error) {
        console.error(error)
        console.warn('init text fail')
      }
    },
    initStorageLanguage(availableLanguage) {
      if(this.storageLanguage) return
      storage.set('anchorLanguage', availableLanguage)
    },
  },
  computed: {
    storageLanguage() {
      return storage.get('anchorLanguage')
    },
    clientLanguage() {
      return this.$store.getters['locale/language']
    },
    textVersion() {
      return this.$store.getters['base/textVersion']
    },
    textCache() {
      return this.$store.getters['base/textCache']
    },
    application() {
      return this.$store.getters['base/application']
    },
  },
  watch: {
    clientLanguage() {
      if(this.$vDatetimePicker) this.$vDatetimePicker.locale = this.clientLanguage
    },
  },
}