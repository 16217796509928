export default {
  namespaced: true,
  state: {
    items: {},
  },
  mutations: {
    set(state, data) {
      state[data.key] = data.value
    },
    appendItem(state, data) {
      let items = _cloneDeep(state.items)
      items[data.key] = data
      state.items = items
    },
    removeItem(state, key) {
      let items = _cloneDeep(state.items)
      delete items[data.key]
      state.items = items
    },
    removeByGroup(state, groupName) {
      let items = _cloneDeep(state.items)
      for(const key in items) {
        const item = state.items[key]
        if(item.group === groupName) {
          delete items[key]
        }

        else {
          continue
        }
      }
      state.items = items
    },
    cleanAll(state) {
      state.items = {}
    },
  },
  actions: {
    set(context, data) {
      context.commit('set', data)
    },
    appendItem(context, data) {
      context.commit('appendItem', data)
    },
    removeItem(context, index) {
      context.commit('removeItem', key)
    },
    removeByGroup(context, groupName) {
      context.commit('removeByGroup', groupName)
    },
    cleanAll(context) {
      context.commit('cleanAll')
    },
  },
  getters: {
    items: state => state.items,
    create: state => !_isEmpty(state.items)
  },
}
