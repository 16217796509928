import { register } from 'register-service-worker'
import * as navigationPreload from 'workbox-navigation-preload'
navigationPreload.enable()
register(`${process.env.BASE_URL}service-worker.js`, {
  ready () {

  },
  registered (registration) {
    window.$sw = registration
  },
  cached () {
    console.log('Content has been cached for offline use.')
  },
  updatefound () {
    console.log('New content is downloading.')
  },
  updated () {
    console.log('New content is available; please refresh.')
  },
  offline () {
    console.log('No internet connection found. App is running in offline mode.')
  },
  error (error) {
    console.error('Error during service worker registration:', error)
  }
})
