import { render, staticRenderFns } from "./hakkatvLayout.vue?vue&type=template&id=8aceaec0&scoped=true&"
import script from "./hakkatvLayout.vue?vue&type=script&lang=babel&"
export * from "./hakkatvLayout.vue?vue&type=script&lang=babel&"
import style0 from "./hakkatvLayout.vue?vue&type=style&index=0&id=8aceaec0&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8aceaec0",
  null
  
)

export default component.exports