<template>
  <div v-if="applicationLoaded" class="print-layout">
    <router-view></router-view>
  </div>
</template>

<script>
import layoutMixin from "@/layouts/layoutMixin.js";
export default {
  mixins: [layoutMixin],
};
</script>

<style lang="scss">
.print-layout {
  padding-top: 100px;
  @media print {
    padding-top: 0;
    @page {
      size: A4;
      margin: 0;
    }
  }
}
</style>