function getConent(obj, key) {
  if(!obj) return ''
  if(!obj[key]) return ''
  return obj[key].content || ''
}

export default {
  namespaced: true,
  state: {
    htmlContent: null,
  },
  mutations: {
    setHtmlContent(state, data) {
      state.htmlContent = data
    },
  },
  actions: {

  },
  getters: {
    customer_service: state => getConent(state.htmlContent, 'customer_service'),
    representation: state => getConent(state.htmlContent, 'representation'),
    representation_intro: state => getConent(state.htmlContent, 'representation_intro'),
    visit: state => getConent(state.htmlContent, 'visit'),
  },
}
