class MustacheRender {
  constructor() {
    this._init()
  }

  async _init() {
    const { default: mustache } = await import(
      /* webpackChunkName: "mustache" */
      /* webpackPrefetch: true */
      'mustache'
    )
    this.mustache = mustache
  }

  render(data, variable) {
    if(!data) return
    let result = ''
    try {
      data = this._escapeBackslashInMustacheVariable(data, variable)
      result = this.mustache.render(data, variable)
    } catch (error) {
      console.warn(error)
    }
    return result
  }

  _escapeBackslashInMustacheVariable(data, variable) {
    if(typeof variable != 'object') return null
    let result = _cloneDeep(variable)
    for(let key in result) {
      let value = result[key]
      const hasBackslash = new RegExp(/\/\//g).test(value)
      if(!hasBackslash) continue
      const regexp = new RegExp(key, 'g')
      data = data.replace(regexp, `{${key}}`)
    }
    return data
  }
}

export default new MustacheRender()