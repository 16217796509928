<template>
  <v-main :anchor-layout="layoutName">
    <v-container class="fill-height grey darken-4" fluid >
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <router-view v-if="applicationLoaded"></router-view>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script lang="babel" type="text/babel">
import layoutMixin from 'layouts/layoutMixin.js'
export default {
  mixins: [layoutMixin],
  data() {
    return {
      layoutName: 'unauthorized-admin',
      timeout: null,
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeout)
    this.timeout = null
  },
  created() {
    this.$ssr()
    this.setupLayout()
    this.checkIdentity()
    this.setupTheme()

    this.timeout = setTimeout(() => {
      console.warn('admin未登入')
      this.setupTheme()
    }, 500)
  },
  methods: {
    async setupTheme() {
      this.setupThemeKernel()
      await this.$helper.delay(0.3)
      this.setupThemeKernel()
      await this.$helper.delay(0.5)
      this.setupThemeKernel()
    },
    setupThemeKernel() {
      this.$vuetify.theme.themes.light = _cloneDeep(this.$vuetify.theme.defaults.light)
    },
    checkIdentity() {
      if(!this.isUser) return
      const path = this.$route.query.path
      if(path) {
        this.$router.replace({ path })
        return
      }
      this.$router.replace({
        name: 'admin.home',
      })
    },
  },
  watch: {
    isUser() {
      this.checkIdentity()
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>