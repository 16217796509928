import Vue from 'vue'
Vue.prototype.$bus = new Vue()

import linkifyHtml from 'linkifyjs/html'
Vue.prototype.linkifyHtml = linkifyHtml

import targetSelector from 'libs/targetSelector.js'
Vue.prototype.$targetSelector = targetSelector

import 'libs/ssr.js'
import 'libs/nl2br'
import 'libs/echo'
import striptags from 'striptags'
window.striptags = striptags
Vue.prototype.$striptags = striptags
import VueTouch from 'vue-touch'
Vue.use(VueTouch, {name: 'v-touch'})

import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

import TreeView from "vue-json-tree-view"
Vue.use(TreeView)

Vue.component('form-post', () => import('components/formPost/formPost'))
Vue.component('array-content', () => import('components/arrayContent.vue'))
Vue.component('v-anchor', () => import('modules/base/components/anchor/anchor2.vue'))
Vue.component('v-notification-sidebar', () => import('@/modules/notification/components/notificationSidebar/notificationSidebar.vue'))
Vue.component('v-notification-toggle-button', () => import('@/modules/notification/components/notificationToggleButton/notificationToggleButton.vue'))
Vue.component('share', () => import('components/share/share.vue'))
Vue.component('share-and-like', () => import('components/shareAndLike/shareAndLike.vue'))
import hakkaPlayer from 'components/player/player.vue'
Vue.component('hakka-player', hakkaPlayer)
Vue.component('index-page', () => import('modules/indexPage/components/indexPage'))
Vue.component('inline-date-picker', () => import('components/inlineDatePicker/inlineDatePicker.vue'))
Vue.component('file-uploader', () => import('components/fileUploader/fileUploader.vue'))
Vue.component('file-list', () => import('components/fileList/fileList.vue'))
Vue.component('time-picker', () => import('components/timePicker/timePicker.vue'))

Vue.component('v-html-editor', () => import(
  /* webpackChunkName: "tinymce" */
  /* webpackPrefetch: true */
  'components/tinymce/tinymce.vue'
  )
)
Vue.component('languages', () => import(
    /* webpackChunkName: "languages" */
    /* webpackPrefetch: true */
    'components/languages/languages.vue'
  )
)

Vue.component('html-content', () => import(
  /* webpackChunkName: "html-content" */
  /* webpackPreload: true */
  'components/htmlContent/htmlContent.vue'
  )
)

Vue.component('photo-viewer', () => import(
    /* webpackChunkName: "photo-viewer" */
    /* webpackPrefetch: true */
    'components/photoViewer/photoViewer.vue'
  )
)

Vue.component('invisible-photo-uploader', () => import(
  /* webpackChunkName: "invisible-photo-uploader" */
  /* webpackPrefetch: true */
  'components/invisiblePhotoUploader/invisiblePhotoUploader.vue'
  )
)
Vue.component('copy-url', () => import(
  /* webpackChunkName: "copy-url" */
  /* webpackPrefetch: true */
  'components/copyUrl/copyUrl.vue'
  )
)

Vue.prototype.$event = new Vue()

import seo from 'libs/seo.js'
Vue.prototype.$seo = seo

// storage
import localStorage from 'libs/storage/localStorage.js'
import sessionStorage from 'libs/storage/sessionStorage.js'

export default {
  created() {
    this.$event.$on('pageDone', () => { console.info('page done') })
    Vue.prototype.$avatar = () => this.avatar
    Vue.prototype.$localStorage = localStorage
    Vue.prototype.$sessionStorage = sessionStorage
  },
  methods: {
    async _loadVueMoment() {
      const { default: VueMoment } = await import(
        /* webpackChunkName: "vue-moment" */
        /* webpackPrefetch: true */
        'vue-moment'
      )

      Vue.use(VueMoment)
    },
    async _loadDatetimePicker() {
      const { default: datetimePicker } = await import(
        /* webpackChunkName: "datetime-picker" */
        /* webpackPrefetch: true */
        'plugins/datetimePicker/installer.js'
      )
      Vue.use(datetimePicker)
    },
    async _loadHotKey() {
      const { default: VueHotkey } = await import(
        /* webpackChunkName: "v-hotkey" */
        /* webpackPrefetch: true */
        'v-hotkey'
      )
      Vue.use(VueHotkey)
    },
    async _loadViewer() {
      const { default: Viewer } = await import(
        /* webpackChunkName: "v-viewer" */
        /* webpackPrefetch: true */
        'v-viewer'
      )
      Vue.use(Viewer, {
        defaultOptions: {
          toolbar: {
            zoomIn: true,
            prev: true,
            reset: true,
            next: true,
            zoomOut: true,
          },
        },
      })
    },
    async _loadValiadtor() {
      // https://github.com/validatorjs/validator.js
      const { default: validator } = await import('validator')
      Vue.prototype.$validator = validator
    },
    async initPluginChunks() {
      this._loadVueMoment()
      this._loadDatetimePicker()
      this._loadHotKey()
      this._loadViewer()
      this._loadValiadtor()
    },
  },
  computed: {
    siteName() {
      return this.$store.getters['base/siteName']
    },
    logoUrl() {
      return this.$store.getters['base/logoUrl']
    },
    tokenAvatar() {
      return this.$store.getters['token/avatar']
    },
    avatar() {
      if(this.tokenAvatar) return this.tokenAvatar
      return this.logoUrl
    },
  },
}