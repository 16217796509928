<template>
  <v-container>
    <component404></component404>
  </v-container>
</template>

<script lang="babel" type="text/babel">
export default {
  components: {
    component404: () => import('components/404/404.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>