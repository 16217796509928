class setupChunks {
  constructor() {
    this.init()
  }

  async init() {
    this._loadFaBrands()
    this._loadFaFontawesome()
    this._loadFaRegular()
    this._loadFaSolid()
    this._loadFaSvg()
    this._loadLazySize()
    // this._loadJquery()
  }

  async _loadFaBrands() {
    await import(
      /* webpackChunkName: "fontawesome-brands" */
      /* webpackPreload: true */
      '@fortawesome/fontawesome-free/css/brands.css'
      )
  }
  async _loadFaFontawesome() {
    await import(
      /* webpackChunkName: "fontawesome-fontawesome" */
      /* webpackPreload: true */
      '@fortawesome/fontawesome-free/css/fontawesome.css'
      )
  }
  async _loadFaRegular() {
    await import(
      /* webpackChunkName: "fontawesome-regular" */
      /* webpackPreload: true */
      '@fortawesome/fontawesome-free/css/regular.css'
      )
  }
  async _loadFaSolid() {
    await import(
      /* webpackChunkName: "fontawesome-solid" */
      /* webpackPreload: true */
      '@fortawesome/fontawesome-free/css/solid.css'
      )
  }
  async _loadFaSvg() {
    await import(
      /* webpackChunkName: "fontawesome-svg-with-js" */
      /* webpackPreload: true */
      '@fortawesome/fontawesome-free/css/svg-with-js.css'
      )
  }
  async _loadJquery() {
    // const { default: $ } = await import(
    //   /* webpackChunkName: "jquery" */
    //   /* webpackPreload: true */
    //   'jquery'
    // )
    // window.self.$ = $
  }

  async _loadLazySize() {
    await import(
      /* webpackChunkName: "lazysizes" */
      /* webpackPreload: true */
      'lazysizes'
      )
  }
}


export default new setupChunks()