export default {
  namespaced: true,
  state: {
    refreshUnreadAction: null,
    status: false,
    config: null,
    role: null,
    loading: false,
    notifications: null,
    hasInit: false,
  },
  mutations: {
    loading(state, data = true) {
      state.loading = data
    },
    set(state, data) {
      state[data.key] = data.value
    },
    setData(state, data) {
      state.notifications = data
    },
    setRole(state, data) {
      state.role = data
    },
    reset(state) {
      state.refreshUnreadAction = null
      state.status = false
      state.config = null
      state.role = null
      state.loading = false
      state.notifications = null
      state.hasInit = false
    },
  },
  actions: {
    loading(context, data) {
      context.commit('loading', data)
    },
    set(context, data) {
      context.commit('set', data)
    },
    setData(context, data) {
      context.commit('setData', data)
    },
    setRole(context, data) {
      context.commit('setRole', data)
    },
    reset(context) {
      context.commit('reset')
    },
  },
  getters: {
    status: state => state.status,
    role: state => state.role,
    loading: state => state.loading,
    notifications: state => state.notifications,
    hasInit: state => state.hasInit,
    refreshUnreadAction: state => state.refreshUnreadAction,
  },
}
