<template>
  <v-snackbar
    v-model="snackbar"
    max-width="1200"
    color="white"
    class="cookie-box rounded-xl"
    timeout="-1"
    vertical
  >
    <div class="d-flex justify-end">
      <v-btn elevation="0" class="mb-3 cookie-close" @click="applyOrClose"
        ><i class="ri-close-line"></i
      ></v-btn>
    </div>
    <div class="d-flex align-center cookie">
      <div class="mb-2 mx-0 cookie-content">
        <p class="mb-0">
          本網站使用 Cookies
          來幫助我們分析數據與改進為您提供的服務。若您繼續瀏覽本網站，代表您同意我們使用
          Cookies，若您不同意，請透過瀏覽器設定選擇拒絕接受。更多資訊請參考
          <router-link
            :to="privacyRoute"
            target="_blank"
            class="text-decoration-underline"
            >隱私權政策</router-link
          >
        </p>
      </div>
    </div>
    <div class="my-1 d-flex justify-center">
      <Mainbtn
        color="white"
        class="ma-3 primary--text accept-btn"
        @click="applyOrClose"
      >
        接受
      </Mainbtn>
    </div>
  </v-snackbar>
</template>
  
  <script>
import Mainbtn from "@/components/cookie/mainBtn.vue";
const cookieConfirmLocalStorageKey = 'cookie_confirm'
export default {
  data: () => ({
    snackbar: false,
  }),
  computed: {
    privacyRoute() {
      return {
        name: 'article',
        params: {
          type: 'audience',
          id: 'private',
        }
      }
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      const cookieConfirm = this.$localStorage.get(cookieConfirmLocalStorageKey, false)
      if (cookieConfirm === true) {
        return
      }

      if (cookieConfirm === 'true') {
        return
      }

      this.snackbar = true
    },
    applyOrClose() {
      this.$localStorage.set(cookieConfirmLocalStorageKey, true)
      this.snackbar = false;
    },
  },
  components: {
    Mainbtn,
  },
};
</script>
<style lang="sass" type="text/sass">
.cookie-box
  .cookie
    color: #333333
    .cookie-content
      max-width: 500px
      width: 100%
      padding:5px 20px
      p
        line-height: 25px
  .cookie-close
    width: 40px !important
    height: 40px !important
    min-width: auto !important
    font-size: 25px
    border-radius: 99rem
    color: #333
    background: #ececec !important
  .accept-btn
    border: 1px solid #56af31
    cursor: pointer
    border-radius: 8px
  .v-snack__wrapper
    border-radius: 15px !important
</style>