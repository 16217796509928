export default {
  namespaced: true,
  state: {
    name: null,
    component: null,
    meta: null,
  },
  mutations: {
    create(state, data) {
      state.name = data.name
      state.component = data.component
      state.meta = data.meta
    },
    remove(state, name) {
      if(state.name != name) return
      state.name = null
      state.component = null
      state.meta = null
    },
    removeForce(state) {
      state.name = null
      state.component = null
      state.meta = null
    }
  },
  actions: {
    create(context, data) {
      context.commit('create', data)
    },
    remove(context, name) {
      context.commit('remove', name)
    },
    removeForce(context) {
      context.commit('removeForce')
    },
  },
  getters: {
    name: state => state.name,
    component: state => state.component,
    meta: state => state.meta,
    created: state => {
      if(!state.name) return false
      if(!state.component) return false
      return true
    },
  },
}
