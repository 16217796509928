class selector {
  active(vm, config) {
    const max = config.max || 0

    vm.$apopup.base({
      width: '80vw',
      max,
      title: config.title,
      bodySlot: config.bodySlot,
      usedList: !Array.isArray(config.usedList) ? [] : _cloneDeep(config.usedList),
      disabledApply: data => {
        if(!Array.isArray(data)) return true
        if(data.length == 0) return true
        if(data.length > max && max != 0) return true
        return false
      },
      applyCallback: data => {
        const result = this._getApplyData(data, max)
        config.applyCallback(result)
      },
      closeCallback: config.closeCallback,
      ...config.meta,
    })
  }

  _getApplyData(data, max) {
    if(!Array.isArray(data)) return null
    if(max == 1) return data[0] || null
    return data
  }
}

export default new selector()