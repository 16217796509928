import Vue from 'vue'
export default {
  data: () => ({
    cheet: null,
    mobileDetectLibrary: null,
  }),
  methods: {
    async baseAppCreated() {
      await this.setupWebpackChunk()
      this.$root.$on('onPageResize', this.onPageResize)
      this.onPageResize()
      this.preventDefaultHotKey()
      this.setupCmsHotKey()
    },
    async baseAppMounted() {
      this.onPageResize()
      this.setupFakerJsLanguage()
      this.initTracker()
      this.setupModuleLaunchScript()
    },
    async setupWebpackChunk() {
      const { default: cheet } = await import(
        /* webpackChunkName: "cheet" */
        /* webpackPrefetch: true */
        'cheet.js'
        )
      this.cheet = cheet

      const { default: mobileDetect } = await import(
        /* webpackChunkName: "mobile-detect" */
        /* webpackPrefetch: true */
        'mobile-detect'
        )
      this.mobileDetectLibrary = mobileDetect
    },
    // 執行各個模組內的 meta.js onAppLaunch callback
    setupModuleLaunchScript() {
      for(const moduleName in this.moduleMetaConfig) {
        const moduleMeta = this.moduleMetaConfig[moduleName]
        if(typeof moduleMeta.onAppLaunch != 'function') continue
        moduleMeta.onAppLaunch(this)
      }
    },
    initTracker() {
      Vue.prototype.$ga = window.ga || null
      Vue.prototype.$dataLayer = window.dataLayer || null
      Vue.prototype.$pixel = window.fbq || null
    },
    setupFakerJsLanguage() {
      if(!this.$helper.faker) return
      if(!this.$helper.faker.locales) return
      const valid = !!this.$helper.faker.locales[this.languageSnackCase]
      if(!valid) return
      this.$helper.faker.locale = this.languageSnackCase
    },
    preventDefaultHotKey() {
      // save
      $(document).bind('keydown', function(e) {
        if(!e.ctrlKey) return
        if(e.which != 83) return
        e.preventDefault()
      })
    },
    onWindowResize() {
      this.$root.$emit('onWindowResize')
      this.onPageResize()
    },
    onPageResize() {
      this.mobileDetect()
      this.$nextTick(() => {
        const pageWidth = $(this.$el)
          .find('*[anchor-layout]>.v-main__wrap')
          .width()
        this.$store.dispatch('base/set', {
          key: 'pageWidth',
          value: pageWidth,
        })

        const windowHeight = $(window).height()
        this.$store.dispatch('base/set', {
          key: 'windowHeight',
          value: windowHeight,
        })

        this.$store.dispatch('base/set', {
          key: 'screenMode',
          value: pageWidth < 680 ? 'v': 'h',
        })
      })
    },
    mobileDetect() {
      if(!this.mobileDetectLibrary) return
      const isMobile = !!(new this.mobileDetectLibrary(window.navigator.userAgent)).mobile()
      const isMobileDetectWindowWidth = $(window).width() < 1264
      const isMobile2 = $(window).width() < 768
      this.$store.dispatch('base/set', {
        key: 'isMobile',
        value: isMobileDetectWindowWidth,
      })
      this.$store.dispatch('base/set', {
        key: 'isMobile2',
        value: isMobile2,
      })
      Vue.prototype.$isMobile = isMobile
      Vue.prototype.$isMobileDetectWindowWidth = isMobileDetectWindowWidth
    },

    // 設定前往cms頁的熱鍵
    setupCmsHotKey() {
      if(!this.site) return
      if(!this.cheet) return
      if(typeof this.site.cmsHotKey != 'string') return
      let cheetString = ''
      const stringArray = Array.from(this.site.cmsHotKey)
      for(const index in stringArray) {
        const char = stringArray[index]
        if(stringArray.length-1 == index) {
          cheetString += `${char}`
        }

        else {
          cheetString += `${char} `
        }
      }

      if(!cheetString) return
      this.cheet(cheetString, () => {
        setTimeout(() => { this.$router.push({ name: 'admin.home' }) }, 500)
      })
    },
  },
  computed: {
    moduleMetaConfig() {
      return this.$store.getters['base/moduleMetaConfig']
    },
    languageSnackCase() {
      return this.$store.getters['locale/languageSnackCase']
    },
    application() {
      return this.$store.getters['base/application']
    },
    textHasLoaded() {
      return this.$store.getters['base/textHasLoaded']
    },
    applicationLoaded() {
      return this.$store.getters['base/applicationLoaded']
    },
    site() {
      return this.$store.getters['base/site']
    },
    device() {
      return this.$store.getters['base/device']
    },
    layout() {
      return this.$store.getters['base/layout']
    },
  },
  watch: {
    layout() {
      this.$nextTick(() => {
        this.onPageResize()
      })
    },
    'site.cmsHotKey'() {
      this.setupCmsHotKey()
    },
    languageSnackCase() {
      this.setupFakerJsLanguage()
    },
  },
}