import env from 'config/env.json'
window.hasEchoFeature = !!env.echo
const manifestLogoUrl = require('assets/hakka-logo.svg')
const manifestIconUrl = require('assets/hakka-logo.svg')
export default {
  namespaced: true,
  state: {
    application: null,
    layout: 'public',
    textHasLoaded: false,
    isMobile: false,
    isMobile2: false,
    loading: false,
    breadcrumbScope: 'default',
    breadcrumb: [],
    fullScreen: false,
    pageWidth: 0,
    windowHeight: 0,
    screenMode: 'h',
    loadingConfig: null,
    isWebviewMode: false,
    isInfoCheck: true, // 預設不顯示圓點
    sortList: [],
    sortMap: {},
    adminPasswordOutdated: true,
  },
  mutations: {
    loading(state, data = true) {
      state.loading = data
    },
    loadingConfig(state, data) {
      state.loadingConfig = data
    },
    setApplication(state, data) {
      // data.tracker.ga.id = 'UA-148071472-3'
      // console.warn('ga...', data.tracker.ga)
      state.application = data
    },
    setLayout(state, data) {
      state.layout = data
    },
    setBreadcrumb(state, data) {
      state.breadcrumb = data
    },
    setBreadcrumbScope(state, data) {
      state.breadcrumbScope = data
    },
    set(state, data) {
      state[data.key] = data.value
    },
    setFullScreen(state, data) {
      state.fullScreen = data
    },
    setIsInfoCheck(state, data) {
      state.isInfoCheck = data
    },
    setSortList(state, data) {
      state.sortList = data
    },
    setSortMap(state, data) {
      state.sortMap = data
    },
    setAdminPasswordOutdated(state, data) {
      state.adminPasswordOutdated = data
    },
  },
  actions: {
    loading(context, data) {
      context.commit('loading', data)
    },
    loadingConfig(context, data) {
      context.commit('loadingConfig', data)
    },
    setApplication(context, data) {
      context.commit('setApplication', data)
    },
    setLayout(context, data) {
      context.commit('setLayout', data)
    },
    setBreadcrumb(context, data) {
      context.commit('setBreadcrumb', data)
    },
    setBreadcrumbScope(context, data) {
      context.commit('setBreadcrumbScope', data)
    },
    set(context, data) {
      context.commit('set', data)
    },
    setFullScreen(context, data) {
      context.commit('setFullScreen', data)
    },
    setIsInfoCheck(context, data) {
      context.commit('setIsInfoCheck', data)
    },
    setSortList(context, data) {
      context.commit('setSortList', data)
    },
    setSortMap(context, data) {
      context.commit('setSortMap', data)
    },
    setAdminPasswordOutdated(context, data) {
      context.commit('setAdminPasswordOutdated', data)
    },
  },
  getters: {
    fullScreen: state => state.fullScreen,
    loadingText: state => !state.loadingConfig ? null : state.loadingConfig.text,
    loading: state => {
      return state.loading
    },
    application: state => state.application,
    theme: (state, getters) => {
      if(!getters.site) return null
      const theme = getters.site.theme
      return theme || 'dark'
    },
    isDark: (state, getters) => getters.theme === 'dark',
    isApplyMode: (state, getters) => {
      if(!getters.site) return null
      return !!getters.site.applyMode
    },
    oauth: state => !state.application ? null : state.application.oauth,
    hasI18n: state => {
      if(!state.application) return null
      return !!window.helper.getRecursive(state.application, `restrict.i18n.status`)
    },
    languageFallback: state => {
      if(!state.application) return null
      if(!state.application.i18n) return null
      if(!state.application.i18n.fallback) return null
      return state.application.i18n.fallback
    },
    textHasLoaded: state => {
      return state.textHasLoaded
    },
    applicationLoaded: state => {
      if(!state.application) return false
      if(!state.textHasLoaded) return false
      return true
    },
    restrict: state => {
      if(!state.application) return null
      if(!state.application.restrict) return null
      return state.application.restrict
    },
    getRestrict: state => {
      return (key) => {
        if(!state.application) return null
        return window.helper.getRecursive(state.application, `restrict.${key}`)
      }
    },
    textVersion: state => {
      if(!state.application) return -1
      helper.getRecursive(state.application, 'text.version')
      return helper.getRecursive(state.application, 'text.version')
    },
    textCache: state => {
      if(!state.application) return false
      helper.getRecursive(state.application, 'text.cache')
      return helper.getRecursive(state.application, 'text.cache')
    },
    layout: state => {
      return state.layout
    },
    pageTypes: state => {
      if(!state.application) return null
      if(!Array.isArray(state.application.pageTypes)) return null
      if(state.application.pageTypes.length < 1) return null
      return state.application.pageTypes
    },
    getPageTypeName: state => {
      return (type) => {
        if(!state.application) return null
        if(!Array.isArray(state.application.pageTypes)) return null
        if(state.application.pageTypes.length < 1) return null
        const result = state.application.pageTypes.find(pageCategoryRoot => pageCategoryRoot.type == type)
        if(!result) return null
        return result.name
      }
    },
    getPageCategoryRootId: state => {
      return (type) => {
        if(!state.application) return null
        if(!Array.isArray(state.application.pageTypes)) return null
        if(state.application.pageTypes.length < 1) return null
        const result = state.application.pageTypes.find(pageCategoryRoot => pageCategoryRoot.type == type)
        if(!result) return null
        return result.id
      }
    },
    getPageCategoryRoot: state => {
      return (type, property) => {
        if(!state.application) return null
        if(!Array.isArray(state.application.pageTypes)) return null
        if(state.application.pageTypes.length < 1) return null
        const result = state.application.pageTypes.find(pageCategoryRoot => pageCategoryRoot.type == type)
        if(!result) return null
        return window.helper.getRecursive(result, property)
      }
    },
    site: state => {
      if(!state.application) return null
      return state.application.site
    },
    siteName: state => {
      if(!state.application) return env.manifest.name
      if(!state.application.site) return env.manifest.name
      return state.application.site.name
    },
    fileBaseUrl: state => {
      if(!state.application) return null
      return state.application.fileBaseUrl
    },
    seo: state => {
      if(!state.application) return null
      if(!state.application.site) return null
      return state.application.site.seo
    },
    logoUrl(state) {
      if(!state.application) return manifestLogoUrl
      const logo = helper.getRecursive(state.application, 'site.seo.logo')
      return helper.getPhotoUrl(logo)
    },
    tinyLogoUrl(state) {
      if(!state.application) return manifestIconUrl
      const logo = helper.getRecursive(state.application, 'site.seo.logo')
      return helper.getPhotoUrl(logo, ['tiny'])
    },
    isMobile: state => {
      return state.isMobile
    },
    isMobile2: state => {
      return state.isMobile2
    },
    device: state => {
      if(state.isMobile == false) return 'desktop'
      return 'mobile'
    },
    kernel: () => {
      return window.kernel
    },
    moduleMetaConfig: () => {
      if(!window.kernel) return {}
      return window.kernel.meta
    },
    adminMenu: () => {
      if(!window.kernel) return null
      if(!window.kernel.menu) return null
      return window.kernel.menu.admin
    },
    memberMenu: () => {
      if(!window.kernel) return null
      if(!window.kernel.menu) return null
      return window.kernel.menu.member
    },
    breadcrumb: state => state.breadcrumb,
    breadcrumbScope: state => state.breadcrumbScope,
    pageWidth: state => state.pageWidth,
    windowHeight: state => state.windowHeight,
    screenMode: state => state.screenMode,
    majorNotification: state => {
      if(!state.application) return null
      return state.application.majorNotification
    },
    env: state => env,
    isWebviewMode: state => state.isWebviewMode,
    maxFileSizeMb: state => env.maxFileSizeMb,
    hasEchoFeature: (state, getters) => getters.env.echo,
    videoWatchDetectIntervalTime: () => 5, // 每N秒偵測影片播放,
    isInfoCheck: state => state.isInfoCheck,
    sortList: state => state.sortList,
    sortMap: state => state.sortMap,
    adminPasswordOutdated: state => state.adminPasswordOutdated
  },
}
