import { currency } from 'config/env.json'
class seo {
  init(vm, data = { siteName, domain, logoUrl }) {
    this.vm = vm
    this.siteName = data.siteName
    this.domain = data.domain
    this.logoUrl = data.logoUrl
  }

  getMetaArray(data = { title, description, photo }) {
    let meta = []

    if(data.title) {
      meta = meta.concat([
        {
          vmid: 'keywords',
          name: 'keywords',
          content: data.title,
        },
        {
          vmid: 'og:title',
          property: 'og:title',
          content: data.title,
        },
      ])
    }

    if(data.description) {
      meta = meta.concat([
        {
          vmid: 'description',
          name: 'description',
          content: data.description,
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: data.description,
        },
      ])
    }

    if(data.photo) {
      meta = meta.concat([
        {
          vmid: 'og:image',
          property: 'og:image',
          content: window.helper.getPhotoUrl(data.photo,   ['middle', 'small', 'tiny']),
        },
      ])
    }

    if(data.type) {
      meta = meta.concat([
        {
          vmid: 'og:type',
          property: 'og:type',
          content: data.type || 'website',
        },
      ])
    }

    if(data.url) {
      meta = meta.concat([
        {
          vmid: 'og:url',
          property: 'og:url',
          content: data.url,
        },
      ])
    }

    if(data.fbAppId) {
      const oauth = this.vm.$store.getters['base/oauth']
      if(oauth) {
        meta = meta.concat([
          {
            vmid: 'fb:app_id',
            property: 'fb:app_id',
            content: oauth.facebook.client,
          },
        ])
      }
    }

    return meta
  }

  getBaseGoogleStructuredData() {
    return [
      {
        type: 'application/ld+json',
        json: {
          '@context': 'https://schema.org',
          '@type': 'Organization',
          url: this.domain,
          name: this.siteName,
        }
      }
    ]
  }

  getPostGoogleStructuredData(data = { title, description, photo, createdAt, updatedAt }) {
    let blogPosting = {
      '@context': 'https://schema.org',
      '@type': 'BlogPosting',
      'mainEntityOfPage': {
        '@type': 'WebPage',
        '@id': this.domain,
      },
      'headline': data.title,
      'datePublished': this.vm.$helper.moment(data.createdAt).format('YYYY-MM-DDTHH:mm:ssZ'),
      'dateModified': this.vm.$helper.moment(data.updatedAt).format('YYYY-MM-DDTHH:mm:ssZ'),
      'image': this.vm.$helper.getPhotoUrlArray(data.photo) || this.logoUrl,
      'author': {
        '@type': 'Organization',
        'name': this.siteName,
      },
      'publisher': {
        '@type': 'Organization',
        'name': this.siteName,
        'logo': {
          '@type': 'ImageObject',
          'url': this.logoUrl,
        }
      },
    }

    if(data.description) {
      blogPosting.description = data.description
    }

    return [
      {
        type: 'application/ld+json',
        json: blogPosting,
      },
    ]
  }

  getProductGoogleStructuredData(data = { code, name, brief, photos, price}) {
    let photos = this.vm.$helper.getComputedPhotoList(data.photos)
    const image = Array.isArray(photos)
      ? photos.map(photo => this.vm.$helper.getPhotoUrl(photo))
      : []

    const url = this.vm.$router.resolve({
      name: 'product-detail',
      params: { code: data.code },
    }).href

    let product = {
      '@context': 'https://schema.org',
      '@type': 'Product',
      'name': data.name,
      'image': image,
      'sku': data.code,
      'offers': {
        '@type': 'Offer',
        'availability': 'https://schema.org/InStock',
        'url': url,
        'priceCurrency': currency,
        'price': data.price,
        'priceValidUntil': '2100-12-31',
      },
    }

    if(data.brief) {
      product.description = data.brief
    }

    return [
      {
        type: 'application/ld+json',
        json: product,
      },
    ]
  }
}

export default new seo()