import Vue from 'vue'
import App from 'App.vue'
import 'registerServiceWorker'
import router from 'router'
import store from 'store'
import vuetify from 'plugins/vuetify'
import apiLibrary from 'libs/api/api.js'
import anchorKernel from 'modules/anchorKernel.js'
import helper from 'libs/helper.js'
import setupCrossTab from 'mainMixins/setupCrosstab'
import oauth from 'libs/oauth'
import fingerprint from 'fingerprintjs'
import mockWebviewCallbackObject from 'libs/mockWebviewCallbackObject'
if(!!storage.get('testAppCallback')) {
  console.warn('測試WebviewCallbackObject已建立')
  window.HakkaTvJavascriptInterface = mockWebviewCallbackObject
}

window.isWebviewMode = () => !!window.HakkaTvJavascriptInterface

Vue.prototype.$oauth = oauth
import $ from 'jquery'
window.self.$ = $
import mainPlugins from 'mainMixins/plugins.js'
import 'mainMixins/setupChunks.js'
import crosstab from 'crosstab'
window.crosstab = crosstab
Vue.config.productionTip = false
window.dataLayer = window.dataLayer || []
import storage from 'libs/storage/localStorage.js'
import visibilityjs from 'visibilityjs'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import customSwiper from '@/components/swiper/customSwiper.vue'
import searchBar from '@/components/searchBar.vue'
import customDatePicker from '@/components/inlineDatePicker/customDatePicker.vue';
import vHtml from "@/modules/base/components/vHtml/vHtml.vue"

Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

Vue.component('c-swiper', customSwiper)
Vue.component('searchBar', searchBar)
Vue.component('c-date-picker', customDatePicker)
Vue.component('V-html', vHtml)

class Application {
  initFingerPrint() {
    let customFingerprint = null
    try {
      customFingerprint = (new URLSearchParams(window.location.search)).get('fingerprint')
    } catch (error) {
      return
    }
    if(!customFingerprint) return
    storage.set('custom-fingerprint', customFingerprint)
  }

  async launch() {
    this.initFingerPrint()

    // 全部模組資料初始完成後, 才開始建立vue root component
    await anchorKernel.init()
    window.kernel = anchorKernel.get()

    window.rootComponent = new Vue({
      router: router(kernel),
      store,
      vuetify,
      render: h => h(App),
      mixins: [mainPlugins, setupCrossTab],
      data: (() => ({
        preventRouteChange: false,
        preventRouteRules: {},
      })),
      beforeDestroy() {
        this.$root.$off('language/set')
      },
      created() {
        this.$oauth.init(this)
        this.initPluginChunks()
        this.$root.$on('language/set', this.changeLanguage)
        this.registerModuleStore()
        this.initKernel()
        this.runModuleBootScripts()
        this.setupVisibilityDetect()
        Vue.prototype.$resetPreventRoute = () => this.preventRouteRules = {}
        Vue.prototype.$preventRoute = (key, value) => {
          if(value == true) {
            this.preventRouteRules[key] = value
          }

          else {
            delete this.preventRouteRules[key]
          }
        }

        // this.$router.replace({name: 'maintain'})
      },
      methods: {
        setupVisibilityDetect() {
          if(visibilityjs.isSupported === false) {
            window.tabFocus = true
            return
          }

          if(visibilityjs.state() === 'visible') {
            window.tabFocus = true
          }

          visibilityjs.change((event, state) => {
            if(state == 'visible') {
              window.tabFocus = true
            }

            else if(state == 'hidden'){
              window.tabFocus = false
            }
          })
        },
        async initKernel() {
          window.helper = helper
          Vue.prototype.$helper = helper
          this.setupCrossTab()
          // 註冊api library到vue prototype讓每個vue元件可使用api
          Vue.prototype.$api = await apiLibrary()

          window.rootComponent.$mount('#app')
          const deviceFingerprint = new fingerprint().get()
          await this.$helper.getAppCallback('getFingerprint')(deviceFingerprint)
        },
        // 執行各個模組要初始化的js
        runModuleBootScripts() {
          for(const script of window.kernel.bootScripts) {
            script(this)
          }
        },
        // 註冊各個模組內的store
        registerModuleStore() {
          for(const storeName in window.kernel.store) {
            const store = window.kernel.store[storeName]
            this.$store.registerModule([storeName], store)
          }
        },
        changeLanguage(config) {
          if(!config.code) return
          if(config.code === storage.get('anchorLanguage')) return
          storage.set('anchorLanguage', config.code)
          this.$store.dispatch('locale/setLanguage', config.code)
          this.$helper.crosstabBroadcast('changeLanguage', config.code)
        },
      },
      computed: {
        application() {
          return this.$store.getters['base/application']
        },
        hasPrevent() {
          for(const key in this.preventRouteRules) {
            const value = this.preventRouteRules[key]
            if(value != true) continue
            return true
          }
          return false
        },
      },
    })
  }
}

(new Application()).launch()