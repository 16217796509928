<template>
  <component :is="component" v-html="htmlContent" class="html-edit"></component>
</template>

<script>
import tableHelper from "./tableHelper";
export default {
  mixins: [tableHelper],
  props: {
    html: String,
    component: {
      type: String,
      default: "div",
    },
    needNl2br: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    htmlContent() {
      if (this.needNl2br) return nl2br(this.html);
      return this.html;
    },
  },
  watch: {
    html() {
      this.initTable();
    },
  },
  mounted() {
    if (this.html) this.initTable();
  },
};
</script>