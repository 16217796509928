<template>
  <div
    hakkatv-player="root"
    class="player elevation-6"
    :style="playerRootStyle"
  >
    <div
      class="player-skelecton"
      :style="playerSkelectonStyle">
    </div>

    <v-img
      v-if="showPreviewPhoto"
      style="position: absolute; top: 0; left: 0;"
      position="center center"
      contain
      :src="previewPhotoUrl"
      :width="width"
      :height="height"
    ></v-img>
  
    <component
      v-if="renderPlayer && playerComponent && !showPreviewPhoto"
      class="player-component"
      @watchDetect="watchDetect"
      @firstPlay="firstPlay"
      @play="play"
      @pause="pause"
      @end="end"
      :is="playerComponent"
      :video="video"
      :width="width"
      :height="height"
      :autoplay="autoplay"
      :startTime="computedStartTime"
      :disabled="disabled"
    ></component>
  </div>
</template>

<script type="text/babel">
import _youtube from '@/components/player/_youtube.vue'
import _videoJs from '@/components/player/_videoJs.vue'
import _get from 'lodash/get'
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    tracking: {
      type: Boolean,
      default: true,
    },
    /**
     * 需要用到的欄位
     * target.id: 目標ID
     * target.title: 目標標題
     */
    target: {
      type: Object,
      default: () => null,
    },
    /**
     * 需要用到的欄位
     * video.title: 影片標題
     * video.type: youtube, stream
     * video.m3u8: m3u8網址
     * video.youtube_url: youtube網址
     * video.photo: video.js預覽圖
     */
    video: {
      type: Object,
      default: () => null,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    startTime: {
      type: [String, Number, null, undefined],
      default: 0,
    },
    full: {
      type: Boolean,
      default: false,
    },
    fullByRatio: {
      type: Boolean,
      default: false,
    },
    ratio: {
      type: Number,
      default: 4/3
    },
    fixHeight: {
      type: [String]
    },
  },
  data: () => ({
    width: 0,
    height: 0,
    renderPlayer: false,
    onUpdateWatchLog: false,
  }),
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      this.setupPlayerSizeInfo()
      this.renderPlayer = false
      if(!this.video) return
      await this.$nextTick()
      this.renderPlayer = true
    },
    videoChange() {
      this.init()
    },
    setupPlayerSizeInfo() {
      this.width = $(this.$el).width()
      this.height = $(this.$el).height()
    },
    // 觀看偵測更新
    watchDetect(data) {
      if(this.tracking) {
        this.$tracking.videoWatchDuration(this.trackingVideoTitle, {
          targetType: this.targetType,
          userType: this.gaUserType,
          targetId: this.targetId,
          duration: data.watchDuration,
        })
      }

      if(this.isMember) {
        this.updateWatchLog(data)
      }
    },
    firstPlay() {
      this.$emit('firstPlay')
      this.seriesViewRequest()
      if(this.tracking) {
        this.$tracking.videoWatch(this.trackingVideoTitle, {
          targetType: this.targetType,
          userType: this.gaUserType,
          targetId: this.targetId,
        })
      }
    },
    async seriesViewRequest() {
      if(this.targetType != 'series') return
      try {
        await this.$api.series.public.view(this.targetId)
      } catch (error) {
        console.warn(error)
      }
    },
    play() {

    },
    pause() {

    },
    end() {

    },
    async updateWatchLog(data) {
      if(data.immediate != true) {
        await this.$helper.delay(5)
      }
      if(this.onUpdateWatchLog) return
      this.onUpdateWatchLog = true
      let params = {
        video_id: this.video.id,
        last_views_sec: data.time,
      }

      if(this.target && this.target.type == 'series') {
        params.series_id = this.target.id
      }

      try {
        await this.$api.video.public.updateWatchLog(params)
      } catch (error) {
        console.warn(error)
      } finally {
        this.onUpdateWatchLog = false
      }
    },
  },
  computed: {
    // 是否顯示預告圖片
    showPreviewPhoto() {
      // 後台不用管
      if (this.isAdmin) {
        return false
      }

      if (!this.previewPhotoUrl) {
        console.warn(`previewPhotoUrl null, check api relationship with [video model]`)
        return false
      }

      return _get(this.video, 'show_video_preview') === true
    },
    previewPhotoUrl() {
      const photo = _get(this.video.video_preview, 'photo')
      return this.$helper.getPhotoUrl(photo)
    },
    layout() {
      return this.$store.getters['base/layout']
    },
    isAdmin() {
      return this.layout === 'admin'
    },
    isMember() {
      return this.$store.getters['token/isMember']
    },
    targetId() {
      if(this.target) return this.target.id
      return this.video.id
    },
    trackingVideoTitle() {
      if(this.target) return this.target.title
      return this.video.title
    },
    targetType() {
      if(!this.target) return this.$VIDEO_CONSTANTS.VIDEO_TRACKING_TARGET_TYPE_VIDEO
      return this.target.type
    },
    gaUserType() {
      return this.$store.getters['token/gaUserType']
    },
    videoWatchDetectIntervalTime() {
      return this.$store.getters['base/videoWatchDetectIntervalTime']
    },
    computedStartTime() {
      const startTime = parseInt(this.startTime)
      if(isNaN(startTime)) return 0
      return startTime
    },
    type() {
      if(!this.video) return null
      return this.video.type
    },
    playerComponent() {
      if(!this.type) return null
      if(this.type === this.$VIDEO_CONSTANTS.VIDEO_TYPE_YOUTUBE) return this.$options.components.youtube
      if(this.type === this.$VIDEO_CONSTANTS.VIDEO_TYPE_STEAM) return this.$options.components.videoJs
      return null
    },
    playerRootStyle() {
      if(this.full) {
        if(this.fullByRatio){
          const num = this.fixHeight.match(/\d+/g);
          const unit = this.fixHeight.match(/[a-zA-Z]+/g);
          return {
            width: num*this.ratio + unit,
            height: '100%',
          }
        }
        return {
          width: '100%',
          height: '100%',
        }
      }
      return {
        width: '400px',
        height: '300px',
      }
    },
    playerSkelectonStyle() {
      if(this.isAdmin) return null
      if(this.fixHeight) return {
        'padding-top': this.fixHeight
      }
      return {
        'padding-top': `${100 / this.ratio}%`
      }
    },
  },
  watch: {
    'video.id': {
      handler() {
        this.videoChange()
      },
    },
    disabled() {
      setTimeout(() => {
        this.init()
      }, 500)
    },
  },
  components: {
    youtube: _youtube,
    videoJs: _videoJs,
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
