<template>
  <div 
    class="hakkaDatePicker" 
    :class="{'dark': isDark, 'is-error': !!showError}"
  >
    <vue-date-picker
      v-bind="$attrs"
      v-model="date"
      format="YYYY-MM-DD"
      valueType="format"
      placeholder="選擇日期"
      @blur="blurHandler"
      @change="changeHandler"
    />
    <p v-if="showError" class="input-message-alert">{{ errorMegs }}</p>
  </div>
</template>

<script>
import vueDatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    vueDatePicker
  },
  props: {
    value: {
      type: String
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessages: String,
    rules: {
      type: Array,
    },
  },
  data: ()=>({
    date: null,
    needValidate: false,
  }),
  computed: {
    isDark() {
      return this.$store.getters['base/isDark']
    },
    showError() {
      if(this.error) return true
      if(!this.needValidate) return false
      if(_isEmpty(this.rules)) return false
      for(let i = 0; i <= this.rules.length - 1; i++) {
        const rule = this.rules[i]
        const res = rule(this.date)
        //若為true, 代表檢查ok => check next
        if(res === true) {
          if(i === this.rules.length - 1) return false
          continue
        }
        return res
      }
      return false
    },
    errorMegs() {
      if(this.error) return this.errorMessages
      if(typeof this.showError === 'string') return this.showError
      return ''
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.date = _cloneDeep(this.value)
      },
    },
    date: {
      deep: true,
      handler() {
        if(_isEqual(this.date, this.value)) return
        this.$emit('input', _cloneDeep(this.date))
      },
    },
  },
  methods: {
    async blurHandler() {
      await this.$nextTick()
      await this.$helper.delay(0.1)
      this.needValidate = true
    },
    async changeHandler() {
      await this.$nextTick()
      this.needValidate = true
    },
    validate() {
      this.needValidate = true
      return !this.showError
    },
  },
}
</script>