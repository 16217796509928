import deepmerge from 'deepmerge'
class anchorKernel {
  constructor() {
    this.config = {
      modules: {},
      routes: {},
      mock: {},
      mockApi: [],
      realApi: [],
      meta: {},
      store: {},
      bootScripts: [],
      widgets: {},
    }
  }

  async init() {
    this.schema = {
      modules: {
        member: import('modules/member/module.js'),
        news: import('modules/news/module.js'),
        video: import('modules/video/module.js'),
        indexPage: import('modules/indexPage/module.js'),
        banner: import('modules/banner/module.js'),
        article: import('modules/article/module.js'),
        post: import('modules/post/module.js'),
        product: import('modules/product/module.js'),
        order: import('modules/order/module.js'),
        debug: import('modules/debug/module.js'),
        user: import('modules/user/module.js'),

        base: import('modules/base/module.js'),
        photo: import('modules/photo/module.js'),
        notification: import('modules/notification/module.js'),
        sms: import('modules/sms/module.js'),
        line: import('modules/line/module.js'),
        mail: import('modules/mail/module.js'),
        slack: import('modules/slack/module.js'),
        lineNotify: import('modules/lineNotify/module.js'),
        text: import('modules/text/module.js'),
      },
    }

    // 展開this.schema.modules
    for(const moduleName in this.schema.modules) {
      const { default: moduleConfig } = await this.schema.modules[moduleName]
      await this._handleModule(moduleName, moduleConfig)
    }
  }

  // 處理單一module
  async _handleModule(moduleName, moduleConfig) {
    this.config.modules[moduleName] = true
    await this._handleModuleBootScript(moduleName, moduleConfig)
    await this._handleModuleMenu(moduleName, moduleConfig)
    await this._handleModuleRoutes(moduleName, moduleConfig)
    await this._handleModuleMock(moduleName, moduleConfig)
    await this._handleModuleMockApi(moduleName, moduleConfig)
    await this._handleModuleRealApi(moduleName, moduleConfig)
    await this._handleModuleStore(moduleName, moduleConfig)
    await this._handleModuleMeta(moduleName, moduleConfig)
  }

  async _handleModuleMock(moduleName, moduleConfig) {
    if(!moduleConfig.mock) return
    for(const mockName in moduleConfig.mock) {
      const { default: mockItem } = await moduleConfig.mock[mockName]
      this.config.mock[mockName] = mockItem
    }
  }

  async _handleModuleMenu(moduleName, moduleConfig) {
    if(moduleConfig.menu instanceof Promise === false) return
    const { default: menu } = await moduleConfig.menu
    this.config.menu = deepmerge(this.config.menu, menu)
  }

  async _handleModuleBootScript(moduleName, moduleConfig) {
    if(typeof moduleConfig.bootScript != 'function') return
    this.config.bootScripts.push(moduleConfig.bootScript)
  }

  async _handleModuleStore(moduleName, moduleConfig) {
    if(!moduleConfig.store) return
    for(const storeName in moduleConfig.store) {
      const { default: storeItem } = await moduleConfig.store[storeName]
      this.config.store[storeName] = storeItem
    }
  }

  async _handleModuleMeta(moduleName, moduleConfig) {
    if(!moduleConfig.meta) return
    const { default: metaConfig } = await moduleConfig.meta
    this.config.meta[moduleName] = metaConfig

    // 設定各模組widget
    if(metaConfig.widgets) {
      this.config.widgets = deepmerge(this.config.widgets, metaConfig.widgets)
    }
  }

  async _handleModuleRoutes(moduleName, moduleConfig) {
    if(moduleConfig.routes instanceof Promise === false) return
    const { default: routes } = await moduleConfig.routes
    for(const layoutName in routes) {
      const layoutRoute = routes[layoutName]
      this.config.routes[layoutName] = deepmerge(this.config.routes[layoutName], layoutRoute)
    }
  }

  async _handleModuleMockApi(moduleName, moduleConfig) {
    if(!moduleConfig.api) return
    if(!moduleConfig.api.mock) return
    const { default: api } = await moduleConfig.api.mock
    if(typeof api != 'function') return
    this.config.mockApi.push(api)
  }

  async _handleModuleRealApi(moduleName, moduleConfig) {
    if(!moduleConfig.api) return
    if(!moduleConfig.api.real) return
    const { default: api } = await moduleConfig.api.real
    if(typeof api != 'function') return
    this.config.realApi.push(api)
  }

  get() {
    return this.config
  }
}

export default new anchorKernel