export default {
  namespaced: true,
  state: {
    languages: [],
    language: null,
    locale: null,
    key: 'language',
  },
  mutations: {
    setLanguages(state, data) {
      state.languages = data
    },
    setLanguage(state, data) {
      state.language = data
    },
    set(state, data) {
      state[data.key] = data.value
    },
  },
  actions: {
    setLanguages(context, data) {
      context.commit('setLanguages', data)
    },
    setLanguage(context, data) {
      context.commit('setLanguage', data)
    },
    set(context, data) {
      context.commit('set', data)
    },
  },
  getters: {
    languagesObject: state => {
      let result = {}
      for(const item of state.languages) {
        if(item.visible != 1) continue
        result[item.code] = item
      }
      return result
    },
    languagesAll: state => {
      return state.languages
    },
    languages: state => {
      return state.languages.filter(language => language.visible == 1)
    },
    language: state => {
      return state.language
    },
    languageSnackCase: state => {
      if(typeof state.language != 'string') return null
      return state.language.replace(/-/, '_')
    },
    locale: state => {
      if(!state.language) return null
      return helper.getCountryCodeFromLanguageCode(state.language, true)
    },
    key: state => {
      return state.key
    },
    getDefaultI18nData: state => {
      return () => {
        let data = {}
        for(const language of state.languages) {
          data[language.code] = null
        }
        return data
      }
    },
    getLabelByCode: state => {
      return (code) => {
        if(!code) return null
        const result = state.languages.find(language => language.code == code)
        if(!result) return null
        return result.label
      }
    }
  },
}
