import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
import customizeRouteRule from "./customizeRouteRule";
import publicLayout from "layouts/public/public.vue";
import public404 from "modules/base/views/public/404.vue";
import unauthorizedAdminLayout from "layouts/unauthorizedAdmin/unauthorizedAdmin.vue";
import adminLayout from "layouts/admin/admin.vue";
import admin404 from "modules/base/views/admin/404.vue";
import print from "layouts/print/print.vue";

class VueRouterObject {
  _getRules() {
    return [
      // 公開
      {
        path: "/",
        component: publicLayout,
        meta: {
          layout: "public",
        },
        children: [
          ...kernel.routes.public,
          {
            path: "404",
            name: "404",
            component: public404,
          },
        ],
      },

      // 會員未登入者
      {
        path: "/unauthorized-member",
        component: () =>
          import("layouts/unauthorizedMember/unauthorizedMember.vue"),
        children: kernel.routes.unauthorizedMember,
        meta: {
          layout: "unauthorized-member",
        },
      },
      // 會員中心
      {
        path: "/member-console",
        component: () => import("layouts/member/member.vue"),
        children: kernel.routes.member,
        meta: {
          layout: "member",
        },
      },

      // 後台未登入者
      {
        path: "/unauthorized-admin",
        component: unauthorizedAdminLayout,
        children: kernel.routes.unauthorizedAdmin,
        meta: {
          layout: "unauthorized-admin",
        },
      },
      // 後台已登入者
      {
        path: "/cms",
        component: adminLayout,
        meta: {
          layout: "admin",
        },
        children: [
          ...kernel.routes.admin,
          {
            path: "404",
            name: "admin.404",
            component: admin404,
          },
        ],
      },

      // 列印
      {
        path: "/print",
        component: print,
        children: [
          {
            path: "calendar",
            name: "print-calendar",
            component: () =>
              import("modules/video/views/print/calendarPage.vue"),
          },
        ],
      },

      // 維修頁
      {
        path: "/maintain",
        name: 'maintain',
        component:  () => import("modules/base/views/maintain.vue"),
      },

      ...customizeRouteRule,
      {
        path: "/cms/*",
        redirect: "/cms/404",
      },
      {
        path: "/*",
        redirect: "/404",
      },
    ];
  }

  init() {
    this.vueRouter = new Router({
      mode: "history",
      base: process.env.BASE_URL,
      routes: this._getRules(),
    });

    this.vueRouter.beforeEach((to, from, next) => {
      let prevent = false;
      if (window.rootComponent) {
        prevent = window.rootComponent.hasPrevent;

        // 有prevent設定
        if (prevent) {
          const confirmResult = confirm(window.i18n("confirm.exit"));
          if (!confirmResult) return;
        }
        window.rootComponent.$resetPreventRoute();
      }

      next();
    });
  }
}

const vueRouterObject = new VueRouterObject();
export default (kernel) => {
  vueRouterObject.init();
  return vueRouterObject.vueRouter;
};
