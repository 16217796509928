import Vue from 'vue'
import env from 'config/env.json'
import laravelEcho from 'laravel-echo'
import socketIoClient from 'socket.io-client'
import channels from 'config/channels.js'
import tokenStore from 'libs/tokenStore'
Vue.prototype.$channels = channels
class echo {
  constructor() {
    this.hasEchoFeature = !!env.echo
    if(!this.hasEchoFeature) return
    this.channelPrefix = env.echo.channelPrefix
    this.launch()
  }

  launch() {
    window.io = socketIoClient
    if(this.$echo) {
      this.$echo.disconnect()
    }

    const laravelEchoInstance = new laravelEcho(this._getLaunchOptions())

    Vue.prototype.$echo = laravelEchoInstance
    Vue.prototype.$getEchoChannel = (channel) => {
      if(!this.channelPrefix) return channel
      return `${this.channelPrefix}${channel}`
    }
  }

  _getLaunchOptions() {
    return {
      broadcaster: 'socket.io',
      host: env.echo.socketbase,
      auth: {
        headers: {
          Authorization: `Bearer ${tokenStore.get()}`,
        },
      }
    }
  }
}

export default new echo()