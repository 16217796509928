<template>
  <v-overlay
    v-if="create"
    anchor-major-notification="overlay"
    fixed
    :opacity="majorNotification.opacity"
    :value="create"
    z-index="999999999"
  >
    <v-card light anchor-major-notification="root">
      <v-card-title>
        <div class="headline">{{title}}</div>
      </v-card-title>

      <v-card-text v-if="content">
        <html-content :value="content"></html-content>
      </v-card-text>
    </v-card>
  </v-overlay>
</template>

<script lang="babel" type="text/babel">
export default {
  computed: {
    create() {
      if(!this.majorNotification) return false
      return this.majorNotification.status
    },
    title() {
      return this.majorNotification.title
    },
    content() {
      return this.majorNotification.content
    },
    majorNotification() {
      return this.$store.getters['base/majorNotification']
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped>
div[anchor-major-notification="root"]
  width: 85vw
</style>