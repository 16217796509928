export default {
  data: () => ({
    vueMeta: {
      title: '',
      link: [],
      meta: [],
      script: [],
    },
  }),
  metaInfo() {
    return {
      title: this.vueMeta.title,
      link: this.vueMeta.link,
      meta: this.vueMeta.meta,
      script: this.vueMeta.script,
      htmlAttrs: {
        lang: this.language,
      },
    }
  },
  methods: {
    async setupMeta() {
      this.vueMeta.title = this.siteName
      if(!this.seo) return
      this.vueMeta.meta = this.$seo.getMetaArray({
        title: this.seo.keywords,
        description: this.seo.description,
        photo: this.seo.photo,
      })

      const iconUrl = this.$helper.getPhotoUrl(this.seo.favicon, ['tiny'])
      this.vueMeta.link = [
        { rel: 'favicon', href: iconUrl },
        { rel: 'shortcut icon', href: iconUrl },
      ]

      const logoUrl = this.$helper.getPhotoUrl(this.seo.logo, ['middle'])
      this.vueMeta.script = this.$seo.getBaseGoogleStructuredData(logoUrl)
    },
  },
  computed: {
    siteName() {
      return this.$store.getters['base/siteName']
    },
    seo() {
      return this.$store.getters['base/seo']
    },
    language() {
      return this.$store.getters['locale/language']
    },
  },
}