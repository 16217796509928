import Vue from 'vue'
import mainVueMeta from 'app/applicationVueMeta.js'
import setupTextAndI18n from 'app/applicationSetupTextAndI18n'
import setupTracker from 'app/applicationSetupTracker'
import notificationApi from 'libs/notificationApi.js'
export default {
  mixins: [mainVueMeta, setupTextAndI18n, setupTracker],
  methods: {
    async applicationCreated() {
      Vue.prototype.$notificationApi = notificationApi
      Vue.prototype.$updateApplication = () => this.getApplicationData(true)
      Vue.prototype.$updateText = () => this.loadText()
      this.initApp(false, true)
    },
    async initApp(isLogin = false, first = false) {
      await this.loginWithEnduringToken()
      await this.renewTokenIfAlmostInvalid()
      await this.getApplicationData()
      await this.loadText(true)
      this.tokenStore.countdown()
      this.setupMeta()
      if(first) this.setupTracker()
      await this.$nextTick()
      this.$notificationApi.init(this)
      this.$seo.init(this, {
        siteName: this.siteName,
        domain: window.location.origin,
        logoUrl: this.logoUrl,
      })
      if(isLogin && this.isAdminUser) this.checkInitialize()
    },
    // 透過長期token登入
    async loginWithEnduringToken() {
      const enduringToken = this.$localStorage.get(this.enduringTokenScope)
      if(!enduringToken) return
      if(this.isLogin) return

      try {
        const result = await this.$api.userAccount.admin.loginWithEnduringToken({
          enduring_token: enduringToken,
        })
        this.$store.dispatch('token/set', result.token)
        this.$helper.afterLoginSuccess(this)
      } catch(error) {
        console.error(error)
        this.$store.dispatch('token/clean')
      }
    },
    // 如果token快過期先renew
    async renewTokenIfAlmostInvalid() {
      // await this.tokenStore.renew()
    },
    async getApplicationData(broadcast = false) {
      try {
        const result = await this.getApplicationApi()
        this.$store.dispatch('base/setApplication', result)
        if(broadcast) crosstab.broadcast('updateApplication', result , null)
      } catch (error) {
        console.error(error)
        console.warn('init application fail')
      }
    },
    async getApplicationApi() {
      if(this.tokenType == 'user') return await this.$api.application.admin.read()
      return await this.$api.application.public.read()
    },
    async checkInitialize() {
      await this.$nextTick()
      if(this.application.initialized) return
      this.$router.replace({ name: 'admin.initialize' })
    },
    async infoCheck() {
      if(this.layout != 'member') return
      if(!this.isLogin) return
      try {
        const res = await this.$api.member.public.infoCheck()
        this.$store.dispatch('base/setIsInfoCheck', res.isFieldDone)
      } catch(err) {
        console.error(err)
      }
    },
  },
  computed: {
    application() {
      return this.$store.getters['base/application']
    },
    enduringTokenScope() {
      return this.$store.getters['token/enduringTokenScope']
    },
    tokenStore() {
      return this.$store.getters['token/tokenStore']
    },
    tokenType() {
      return this.$store.getters['token/type']
    },
    isAdminUser() {
      return this.$store.getters['token/isAdminUser']
    },
    isLogin() {
      return this.$store.getters['token/isLogin']
    },
    logoUrl() {
      return this.$store.getters['base/logoUrl']
    },
    layout() {
      return this.$store.getters['base/layout']
    },
  },
  watch: {
    async isLogin() {
      await this.$nextTick()
      this.initApp(true)
      this.infoCheck()
    },
    $route: {
      deep: true,
      handler() {
        this.tokenStore.setupScope()
      }
    }
  },
}