<template>
  <v-app anchor-root
    v-resize="onWindowResize"
    :device="device">

    <!--Layout-->
    <router-view
    ></router-view>

    <appExtra
      v-if="applicationLoaded"
    ></appExtra>
  </v-app>
</template>

<script>
import Vue from 'vue'
import tracking from 'libs/tracking.js'
import appExtra from 'appExtra/appExtra.vue'
import base from 'app/base.js'
import application from 'app/application.js'
export default {
  name: 'App',
  mixins: [base, application],
  async created() {
    this.$store.dispatch("base/loading");
    Vue.prototype.$tracking = tracking(this)
    await this.applicationCreated()
    await this.baseAppCreated()
    this.$store.dispatch('base/set', {
      key: 'isWebviewMode',
      value: window.isWebviewMode(),
    })
    this.$store.dispatch("base/loading", false);
  },
  mounted() {
    this.baseAppMounted()
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    this.infoCheck()
  },
  methods: {
    goTop() {
      this.$nextTick(() => {
        this.$vuetify.goTo(0)
      })
    },
  },
  components: {
    appExtra,
  },
  watch: {
    $route: {
      deep: true,
      handler(after, before) {
        if(after.name != before.name) return this.goTop()
        if(_isEqual(after.params, before.params) === false) return this.goTop()
        if(_isEqual(after.query, before.query) === false) return this.goTop()
      },
    }
  },
}
</script>

<style src="@/assets/sass/base.sass" type="text/sass" lang="sass"></style>
<style src="prismjs/themes/prism.css"></style>
<style src="viewerjs/dist/viewer.css"></style>
<style src="@/assets/sass/index.sass" type="text/sass" lang="sass"></style>
<style lang="sass" type="text/sass">
@media print
  *[anchor-layout]
    padding-left: 0 !important
</style>