<template>
  <v-main :anchor-layout="layoutName">
    <v-navigation-drawer
      v-if="!adminPasswordOutdated"
      app clipped dark
      class="d-print-none"
      v-model="showNavigation"
    >
      <adminNavigation
        v-if="applicationLoaded"
      ></adminNavigation>
    </v-navigation-drawer>

    <v-app-bar app fixed clipped-left dark class="d-print-none">
      <v-app-bar-nav-icon v-if="!adminPasswordOutdated" @click.stop="toggleNavigation"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-toolbar-title class="d-flex pa-2 align-center">
        <span class="pa-2">{{siteName}}</span>
      </v-toolbar-title>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-container
      v-if="isUser && applicationLoaded"
      :fluid="fullScreen"
    >
      <breadcrumb></breadcrumb>
      <div anchor-router-view-container>
        <router-view></router-view>
      </div>
    </v-container>
  </v-main>
</template>

<script lang="babel" type="text/babel">
import adminNavigation from 'layouts/admin/adminNavigation.vue'
import layoutMixin from 'layouts/layoutMixin.js'
import Vue from 'vue'
export default {
  mixins: [layoutMixin],
  data() {
    return {
      showNavigation: false,
      layoutName: 'admin',
    }
  },
  created() {
    this.$ssr()
    this.showNavigation = !this.$isMobileDetectWindowWidth ? true : false
    this.setupLayout()
    this.checkIdentity()
    this.checkPassword()
  },
  mounted() {
    this.setupTheme()
  },
  methods: {
    async setupTheme() {
      if(!this.isUser) return
      this.setupThemeKernel()
      await this.$helper.delay(0.3)
      this.setupThemeKernel()
      await this.$helper.delay(0.5)
      this.setupThemeKernel()
    },
    async setupThemeKernel() {
      this.$nextTick()
      this.$vuetify.theme.themes.light = _cloneDeep(this.$vuetify.theme.defaults.cms)
    },
    checkIdentity() {
      if(this.isUser) return
      this.$router.replace({
        name: 'admin.login',
        query: {
          path: this.$route.fullPath,
        }
      })
    },
    toggleNavigation() {
      this.showNavigation = !this.showNavigation
      this.$nextTick(() => {
        this.$root.$emit('onPageResize')
      })
    },
    async checkPassword() {
      try {
        // call api
        const {isFirst, isOverChange} = await this.$api.user.admin.checkPasswordRecord()
        const isOutdated = isFirst || isOverChange
        this.$store.dispatch('base/setAdminPasswordOutdated', isOutdated)
        if(isOutdated) {
          this.$router.replace({
            name: 'admin.account.reset-password'
          })

          if(isOverChange) {
            setTimeout(() => {
              this.$apopup.base({
                title: '請修改密碼',
                content: '為提升本會資訊系統之安全性，請重新設定高強度密碼，並至少每六個月更新一次與前三次不同的密碼',
              })
            }, 500)
          }
     
        }
      } catch(err) {}
    },
  },
  watch: {
    isUser() {
      this.checkIdentity()
    },
    fullScreen() {
      setTimeout(() => this.showNavigation = !this.fullScreen, 1000)
    },
  },
  computed: {
    fullScreen() {
      return this.$store.getters['base/fullScreen']
    },
    siteName() {
      return this.$store.getters['base/siteName']
    },
    tinyLogoUrl() {
      return this.$store.getters['base/tinyLogoUrl']
    },
    adminPasswordOutdated() {
      return this.$store.getters['base/adminPasswordOutdated']
    },
  },
  components: {
    adminNavigation,
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>