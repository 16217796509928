// import prefectScrollbar from "components/scrollbar/prefectScrollbar.js";
export default {
//   mixins: [prefectScrollbar],
  data: ()=>({
    hasY: false
  }),
  computed: {
 
  },
  async mounted() {
    this.$root.$on('initTable', this.initTable)
  },
  beforeDestroy() {
    this.$root.$off('initTable', this.initTable)
  },
  methods: {
    async initTable() {
      await this.$helper.delay(0.5)
      this.wrapTable();

      // await this.$helper.delay(0.5)
      // this.$target = $(this.$el).find(".table-wrap")
    //   this.initScrollbar();
    },
    wrapTable() {
      const _this = this;
      $(this.$el)
        .find("table")
        .each(function() {
          if($(this).hasClass('table--wrapped')) return
          // console.log(this)
          $(this).wrap(`<div class="table-wrap"></div>`);
          $(this).addClass('table--wrapped')
        });
    },
  },
};
