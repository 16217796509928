<template>
  <div>
    <searchKeywords
      class="searchBar"
      @search="changeRoute"
      :comboboxProperties="comboboxProperties"
    ></searchKeywords>
  </div>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    searchType: {
      type: String,
    },
  },
  methods: {
    changeRoute(data) {
      // 清空
      if(!data.value) {
        if(this.$route.name !== 'search') return
        this.$router.push({ name: 'search', query: {} })
        return
      }
      const params = {}
      let query = {
        type: data.type,
        q: this.getSearchDataValue(data),
      }
      if(data.type == 'tag') {
        query.q = this.getSearchDataValue(data)
      }
      if(this.searchType === 'news') {
        query.filter = 'news'
      }
      if(this.searchType === 'post') {
        query.filter = 'post'
      }
      params.name = 'search'
      params.query = query
      this.$router.push(params)
    },
    getSearchDataValue(searchData) {
      if(!searchData) return null
      if(typeof searchData.value == 'object') {
        return searchData.value.name
      }

      return searchData.value || null
    },
  },
  computed: {
    comboboxProperties() {
      return {
        outlined: true,
      }
    }
  },
  components: {
    searchKeywords: () => import('@/components/searchKeyword/searchKeywords.vue'),
  },
}
</script>