import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import base from 'store/modules/base.js'
import token from 'store/modules/token.js'
import locale from 'store/modules/locale.js'
import list from 'store/modules/list.js'
import form from 'store/modules/form.js'
import view from 'store/modules/view.js'
import tree from 'store/modules/tree.js'
import bottomNavigation from 'store/modules/bottomNavigation.js'
import sidebar from 'store/modules/sidebar.js'
import notification from 'store/modules/notification.js'
import calendar from 'store/modules/calendar.js'
import service from 'store/modules/service.js'

export default new Vuex.Store({
  modules: {
    token,
    base,
    locale,
    list,
    form,
    view,
    tree,
    bottomNavigation,
    sidebar,
    notification,
    calendar,
    service,
  },
  state: {},
  mutations: {},
  actions: {},
})
