<template>
  <v-main
    :anchor-layout="layoutName"
    :hakka-theme="theme"
    :layout-page="routeName"
    :class="routeName"
  >
    <v-navigation-drawer
      v-if="isMobile"
      class="v-navigation-drawer--mobile d-print-none"
      app
      clipped
      left
      :dark="isDark"
      v-model="showNavigation"
    >
      <v-list dense class="hidden-lg-and-up">
        <v-list-item
          exact
          exact-active-class="router-link-exact-active"
          class="font-weight-light text-primary"
        >
          <v-list-item-title>
            <v-icon @click="showNavigation = false">ri-close-line</v-icon>
          </v-list-item-title>
        </v-list-item>

        <v-list-item
          exact
          exact-active-class="router-link-exact-active"
          v-if="!isMember"
          class="font-weight-light text-primary py-3"
          style="background: #52b24d"
          :to="{ name: 'member.login' }"
        >
          <span class="mr-2">
            <v-img
              width="16"
              height="16"
              :src="require('@/assets/img/member.png')"
            ></v-img>
          </span>
          <v-list-item-title> 註冊 / 登入 </v-list-item-title>
        </v-list-item>

        <v-list-item
          exact
          exact-active-class="router-link-exact-active"
          v-if="isMember"
          class="font-weight-light text-primary"
          :to="{ name: 'member.profile' }"
        >
          <span class="mr-2">
            <v-img
              width="16"
              height="16"
              :src="require('@/assets/img/member.png')"
            ></v-img>
          </span>
          <v-list-item-title class="d-flex">
            <span v-if="showNotify" class="notify-icon"></span>會員中心
          </v-list-item-title>
        </v-list-item>

        <v-list-item
          exact
          exact-active-class="router-link-exact-active"
          class="font-weight-light text-primary"
          :to="isMember ? { name: 'member.favorite' } : { name: 'member.login' }"
        >
          <v-list-item-title> 我的收藏 </v-list-item-title>
        </v-list-item>

        <v-list-item
          exact
          exact-active-class="router-link-exact-active"
          class="font-weight-light text-primary"
          :to="isMember ? { name: 'member.watchHistory' } : { name: 'member.login' }"
        >
          <v-list-item-title> 觀看紀錄 </v-list-item-title>
        </v-list-item>

        <v-divider
          class="goBackVuetifyStyle border-opacity-50 ml-3"
        ></v-divider>

        <v-list-item
          exact
          exact-active-class="router-link-exact-active"
          class="font-weight-light text-primary"
          :to="{ name: 'news' }"
        >
          <v-list-item-title>新聞</v-list-item-title>
        </v-list-item>

        <v-list-item
          exact
          exact-active-class="router-link-exact-active"
          class="font-weight-light text-primary"
          :to="{ name: 'child' }"
        >
          <v-list-item-title> 兒少 </v-list-item-title>
        </v-list-item>

        <v-list-item
          exact
          exact-active-class="router-link-exact-active"
          class="font-weight-light text-primary"
          :to="{ name: 'drama' }"
        >
          <v-list-item-title> 戲劇 </v-list-item-title>
        </v-list-item>

        <v-list-item
          :to="{ name: 'program' }"
          exact
          exact-active-class="router-link-exact-active"
          class="font-weight-light text-primary"
        >
          <v-list-item-title> 節目 </v-list-item-title>
        </v-list-item>

        <v-list-item
          :to="{ name: 'live' }"
          exact
          exact-active-class="router-link-exact-active"
          class="font-weight-light text-primary"
        >
          <v-list-item-title> 直播 </v-list-item-title>
        </v-list-item>

        <v-list-item
          exact
          exact-active-class="router-link-exact-active"
          class="font-weight-light text-primary"
          :to="{ name: 'schedule' }"
        >
          <!-- <span class="mr-2">
            <v-img
              width="16"
              height="16"
              :src="require('@/assets/img/table.png')"
            ></v-img>
          </span> -->
          <v-list-item-title> 節目表 </v-list-item-title>
        </v-list-item>

        <v-divider
          class="goBackVuetifyStyle border-opacity-50 ml-3"
        ></v-divider>

        <v-list-item
          v-if="isMember"
          exact
          exact-active-class="router-link-exact-active"
          class="font-weight-light text-primary"
          @click="logout"
        >
          <v-list-item-title> 登出 </v-list-item-title>
        </v-list-item>

        <v-divider
          v-if="isMember"
          class="goBackVuetifyStyle border-opacity-50 ml-3"
        ></v-divider>

        <div class="d-flex">
          <template v-for="platform in platforms">
            <v-hover v-slot="{ hover }" :key="platform.value">
              <a
                class="social-icon-area pl-3 py-3"
                target="_blank"
                :href="platform.link"
                transition="fade-transition"
              >
                <v-img
                  class="social-icon"
                  :width="iconSize"
                  :height="iconSize"
                  contain
                  :src="platform.photos[0]"
                ></v-img>
                <v-img
                  class="social-icon-hover"
                  :width="iconSize"
                  :height="iconSize"
                  contain
                  :src="platform.photos[1]"
                ></v-img>
              </a>
            </v-hover>
          </template>
        </div>

        <v-list-item class="font-weight-light text-primary translateBox">
          <translate />
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <desktop
      v-if="createDesktopLayout"
      :showNavigation="showNavigation"
    ></desktop>

    <mobile
      v-if="createMobileLayout"
      @toggleNavigation="toggleNavigation"
      :showNavigation="showNavigation"
    ></mobile>

    <div
      v-if="applicationLoaded"
      anchor-router-view-container
      :class="{ container: hasPageGap }"
    >
      <!-- 會員中心layout -->
      <div
        class="d-flex flex-md-row flex-column"
        v-if="layoutName === 'member'"
      >
        <memberSideBar></memberSideBar>

        <div class="member-route-view flex-grow-1">
          <breadcrumb></breadcrumb>
          <router-view :page="$route.name"></router-view>
        </div>
      </div>

      <template v-else>
        <breadcrumb></breadcrumb>
        <router-view :page="$route.name" :class="$route.name"></router-view>
      </template>
    </div>

    <facebookPlugin></facebookPlugin>
    <partialFooter></partialFooter>
    <Cookie v-if="layoutName === 'public'"></Cookie>
  </v-main>
</template>

<script lang="babel" type="text/babel">
import layoutMixin from 'layouts/layoutMixin.js'
import publicHighlight from 'layouts/public/publicHighlight.vue'
import Cookie from '@/components/cookie/cookie.vue'
export default {
  props: {
    layoutName: String,
  },
  mixins: [layoutMixin],
  data() {
    return {
      showNavigation: false,
      platforms: [
        {
          label: "Facebook",
          value: "facebook",
          link: "https://www.facebook.com/hakkatv/",
          photos: [
            require("@/assets/icon/facebook-white-thin.svg"),
            require("@/assets/icon/facebook-green-thin.svg"),
          ],
        },
        {
          label: "Instagram",
          value: "instagram",
          link: "https://www.instagram.com/hakkatv_2003/",
          photos: [
            require("@/assets/icon/instagram-white.svg"),
            require("@/assets/icon/instagram-green.svg"),
          ],
        },
        {
          label: "Youtube",
          value: "youtube",
          link: "https://www.youtube.com/user/hakkatv",
          photos: [
            require("@/assets/icon/youtube-white.svg"),
            require("@/assets/icon/youtube-green.svg"),
          ],
        },
      ],
      iconSize: 40
    }
  },
  created() {
    this.setupLayout()
  },
  mounted() {
    this.setupTheme()
  },
  methods: {
    logout() {
      this.$helper.logout(this)
    },
    async setupTheme() {
      this.setupThemeKernel()
      await this.$helper.delay(0.3)
      this.setupThemeKernel()
      await this.$helper.delay(0.5)
      this.setupThemeKernel()
    },
    setupThemeKernel() {
      this.$vuetify.theme.themes.light = _cloneDeep(this.$vuetify.theme.defaults.light)
    },
    toggleNavigation() {
      this.showNavigation = !this.showNavigation
      this.$nextTick(() => {
        this.$root.$emit('onPageResize')
      })
    },
  },
  computed: {
    showNotify() {
      return !this.$store.getters['base/isInfoCheck']
    },
    createDesktopLayout() {
      return !this.isMobile && this.applicationLoaded
    },
    createMobileLayout() {
      if(this.isWebviewMode) return false
      return this.isMobile && this.applicationLoaded
    },
    isWebviewMode() {
      return this.$store.getters['base/isWebviewMode']
    },
    isDark() {
      return this.$store.getters['base/isDark']
    },
    isMobile() {
      return this.$store.getters['base/isMobile']
    },
    routeName() {
      return this.$route.name
    },
    theme() {
      return this.$store.getters['base/theme']
    },
    isUser() {
      return this.$store.getters['token/isUser']
    },
    isMember() {
      return this.$store.getters['token/isMember']
    },
  },
  watch: {
    isMember: {
      immediate: true,
      handler() {
        this.$tracking.changeUserType(this.isMember)
        this.$tracking.changeUserTypeForGa4(this.isMember)
      },
    },
  },
  components: {
    Cookie,
    // facebookPlugin: () => import('components/facebookPlugin/flatButton.vue'),
    facebookPlugin: () => import('components/facebookPlugin/facebookPlugin.vue'),
    partialFooter: () => import('layouts/footer.vue'),
    memberSideBar: () => import('layouts/member/memberSideBar.vue'),
    desktop: () => import('layouts/hakkatvLayout/desktop.vue'),
    mobile: () => import('layouts/hakkatvLayout/mobile.vue'),
    translate: () => import('@/components/translate/translate.vue')
  },
}
</script>

<style lang="sass" type="text/sass" scoped>
*[anchor-layout="public"], *[anchor-layout="member"], *[anchor-layout="unauthorized-member"]
  background: #0a0c0f
  *[anchor-router-view-container]

  &[hakka-theme="light"]
    background: #F7F7F7
    *[anchor-router-view-container]
      padding-bottom: 0px
  &[layout-page="child"]
    background: #fff !important
    .showCaseTitle
      color: rgba(0,0,0,.87) !important
.social-icon-area
  .social-icon-hover
    display: none
  &.on-Hover
    .social-icon
      display: none
      .social-icon-hover
        display: block
</style>
