class NotificationApi {
  async init(vm) {
    this.vm = vm
    if(('Notification' in window) === false) return
    this.siteName = this.vm.$store.getters['base/siteName']
    this.logoUrl = this.vm.$store.getters['base/tinyLogoUrl']
    if(this.getPermission() != 'granted') {
      await this.request()
    }
  }

  getPermission() {
    return window.Notification.permission
  }

  notify(message, customTitle = null, customOptions = null) {
    let title = !customTitle ? this.siteName : customTitle
    let options = {
      body: message,
      icon: this.logoUrl,
      vibrate: [200, 100, 200],
    }
    if(customOptions) options = customOptions
    $sw.showNotification(title, options)
  }

  async request() {
    if(('Notification' in window) === false) return

    try {
      const status = await window.Notification.requestPermission()
      if(status != 'granted') return
      this.notify(this.vm.i18n('notification-api.enable-successfully'))
    } catch (error) {
      console.error(error)
      this.enableWarning()
    }
  }

  enableWarning() {
    this.vm.$snotify.warning(
      this.vm.i18n('notification-api.denied.help'),
      this.vm.i18n('notification-api.denied')
    )
  }

}

export default new NotificationApi