export default {
  methods: {
    setupCrossTab() {
      try {
        this._setupCrossTabEventListener()
      } catch (error) {
        console.warn(this.i18n('device.not_support_crosstab'), error)
      }
    },
    _setupCrossTabEventListener() {
      this._setupToken()
      this._setupApplication()
    },
    _setupToken() {
      crosstab.on('setToken', event => {
        const isSelf = crosstab.id == event.origin
        if(isSelf) return
        const eventData = event.data
        if(eventData.scope != this.tokenScope) return
        this.$store.dispatch('token/set', eventData.token)
      })

      crosstab.on('cleanToken', event => {
        const isSelf = crosstab.id == event.origin
        if(isSelf) return
        const eventData = event.data
        if(eventData.scope != this.tokenScope) return
        this.$store.dispatch('token/clean')
      })

      crosstab.on('changeLanguage', event => {
        const isSelf = crosstab.id == event.origin
        if(isSelf) return
        const code = event.data
        this.$store.dispatch('locale/setLanguage', code)
      })
    },
    _setupApplication() {
      crosstab.on('updateApplication', event => {
        const isSelf = crosstab.id == event.origin
        if(isSelf) return
        this.$store.dispatch('base/setApplication', event.data)
      })
    }
  },
  computed: {
    tokenScope() {
      return this.$store.getters['token/scope']
    },
  },
}