export default {
  data: () => ({
    menuData: null,
  }),
  methods: {
    setupNode(node) {
      node.shouldCreate = true
      if(Array.isArray(node.group)) {
        node.type = 'group'
        return node
      }
      node.type = 'route'
      let hasRole = this.tokenStore.hasRole(node.roles)
      let create = true
      if(typeof node.create == 'function') create = node.create(this.getRestrict, this.isMaintainer)
      if(hasRole == false || create == false) node.shouldCreate = false
      return node
    },
    getComputedNodeList(nodes) {
      nodes = _cloneDeep(nodes)
      let result = []
      for(let node of nodes) {
        if(typeof node == 'function') {
          node = node(this)
        }
        if(!node) continue
        node = this.setupNode(node)
        if(Array.isArray(node.group)) {
          node.group = this.getComputedNodeList(node.group)
        }
        result.push(node)
      }
      return result
    },
    getComponent(node) {
      if(node.type == 'group') return 'menuNodeGroup'
      return 'menuNodeLink'
    },
    renderMenu() {},
    updateMenuByModule(menu) {
      for(const moduleName in this.moduleMetaConfig) {
        const metaConfig = this.moduleMetaConfig[moduleName]
        if(typeof metaConfig.updateMenu != 'function') continue
        metaConfig.updateMenu(this, menu)
      }
    }
  },
  computed: {
    moduleMetaConfig() {
      return this.$store.getters['base/moduleMetaConfig']
    },
    isMaintainer() {
      return this.$store.getters['token/isMaintainer']
    },
    tokenStore() {
      return this.$store.getters['token/tokenStore']
    },
    tokenData() {
      return this.$store.getters['token/data']
    },
    hasI18n() {
      return this.$store.getters['base/hasI18n']
    },
    restrict() {
      return this.$store.getters['base/restrict']
    },
    getRestrict() {
      return this.$store.getters['base/getRestrict']
    },
  },
  watch: {
    restrict: {
      deep: true,
      handler() {
        this.renderMenu()
      },
    },
  },
  components: {
    menuNodeLink: () => import('./link.vue'),
    menuNodeGroup: () => import('./group.vue'),
  },
}